import React from 'react'
import AboutUs from '../components/about/AboutUs'
import { Footer } from '../components/Footer'
import Header from '../components/Navbar/Navbar'
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div>
      <Helmet>
        <title>About - Vikas Plywood - One Stop Solution</title>
        <meta name="description" content="Family-owned, guided by vision & values of respect and familial ethics. We believe in growing together. Learn more." />
        <meta property="og:url" content="https://www.vikasplywood.com/about " />
        <meta property="og:keywords" content="Vikas plywood, Family business in Hyderabad, Let's grow together, Goel family"/>
        <meta name="keywords" content="Vikas plywood, Family business in Hyderabad, Let's grow together, Goel family"/>
        <meta name="og:keywords" content="Vikas plywood, Family business in Hyderabad, Let's grow together, Goel family"/>

        <meta property="og:type" content="website" />
      </Helmet>
      <Header logo="logomenu.png" color ="black"/>   
      <AboutUs/>
      <Footer/>
    </div>
  )
}

export default About
