import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CustomCursor = () => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [isHoveringDragg, setIsHoveringDragg] = useState(false);
    const [showFullScale, setShowFullScale] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleMouseMove = (event) => {
            setCursorPosition({ x: event.clientX, y: event.clientY });
        };

        const handleDraggEnter = () => {
            setIsHoveringDragg(true);
            setShowFullScale(true);
        };

        const handleDraggLeave = () => {
            setIsHoveringDragg(false);
            setShowFullScale(false);
        };

        const setupEventListeners = () => {
            const draggElements = document.querySelectorAll(".aboutpicture, .aboutpicturee");
            draggElements.forEach(draggElement => {
                draggElement.addEventListener("mouseenter", handleDraggEnter);
                draggElement.addEventListener("mouseleave", handleDraggLeave);
            });
        };

        const observer = new MutationObserver(() => {
            setupEventListeners();
        });

        observer.observe(document.body, { childList: true, subtree: true });

        document.addEventListener('mousemove', handleMouseMove);

        setupEventListeners();

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            const draggElements = document.querySelectorAll(".aboutpicture, .aboutpicturee");
            draggElements.forEach(draggElement => {
                draggElement.removeEventListener("mouseenter", handleDraggEnter);
                draggElement.removeEventListener("mouseleave", handleDraggLeave);
            });
            observer.disconnect();
        };
    }, [location]);

    if (window.innerWidth <= 768) {
        return null;
    }

    return (
        <div>
            <img
                className={`w-[5vw] ${isHoveringDragg ? "hidden" : ""}`}
                src={require('../assets/images/cursor1.png')}
                style={{
                    position: 'fixed',
                    left: cursorPosition.x + 'px',
                    top: cursorPosition.y + 'px',
                    width: '5vw',
                    height: 'auto',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 50000,
                    pointerEvents: 'none',
                }}
                alt=""
            />
            {isHoveringDragg && (
                <div
                    style={{
                        position: 'fixed',
                        left: cursorPosition.x + 'px',
                        top: cursorPosition.y + 'px',
                        transform: `translate(-50%, -50%) scale(${showFullScale ? '1' : '0.5'})`,
                        zIndex: 50000,
                        pointerEvents: 'none',
                        transition: 'transform 0.2s ease, opacity 0.1s ease',
                        opacity: showFullScale ? 1 : 0.1,
                    }}
                    className="text-[1.2vw] font-thin w-[5vw] h-[5vw] flex justify-center items-center font-['Poppins'] rounded-full shadow-2xl bg-[#0000002a] text-white backdrop-blur-[1.5px]"
                >
                    <div className='flex flex-col justify-center items-center'>
                        <img className='w-[3vw]' src={require("../assets/scroll-down-mouse.webp")} alt="" />
                        <p className='text-[1vw] relative top-[-0.3vw]'>Scroll</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomCursor;
