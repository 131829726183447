export const varietyImages = {
    'Plywood': [
        { 
            src: require("../assets/images/products/plywood/plyboards.webp"),
            alt: "Plywood sheets"
        },
        { 
            src: require("../assets/images/products/plywood/plywood sheets.webp"),
            alt: "Thin layers of genuine wood"
        },
        { 
            src: require("../assets/images/products/plywood/plywood background.webp"),
            alt: "Plywood sheets"
        }
    ],
    'Veneers': [
        { 
            src: require("../assets/images/products/veneer/cover.webp"),
            alt: "Veneer cover image"
        },
        { 
            src: require("../assets/images/products/veneer/cover.webp"),
            alt: "Veneer cover image"
        }
    ],
    'Laminates': [
        { 
            src: require("../assets/images/products/laminates/cover.jpg"),
            alt: "A library unit with a high-end laminate finish"
        },
        { 
            src: require("../assets/images/products/laminates/cover.jpg"),
            alt: "A library unit with a high-end laminate finish"
        }
    ],
    'Wooden Flooring': [
        { 
            src: require("../assets/images/products/wooden_flooring/Background Pictures/VIN Flooring_Cover.webp"),
            alt: "A close up look of designer wooden flooring at VIN"
        },
        { 
            src: require("../assets/images/products/wooden_flooring/Background Pictures/Wooden Flooring Texture_Cover.webp"),
            alt: "Geometric wooden flooring design"
        }
    ],
    'Wall Panels': [
        { 
            src: require("../assets/images/products/wall/agt/Background/1.jpg"),
            alt: "AGT 2050 decorative wall panels in the sun"
        },
        { 
            src: require("../assets/images/products/wall/agt/Background/2.jpg"),
            alt: "AGT 3783 decorative wall panels in the sun"
        }
    ],
    'Modular Interiors': [
        { 
            src: require("../assets/images/Rectangle 187.png"),
            alt: "Modular kitchen with PU and veneer finish"
        },
        { 
            src: require("../assets/images/Rectangle 188.png"),
            alt: "Modular wardrobe with glass finish by VIN"
        }
    ],
    'Exterior Materials': [
        { 
            src: require("../assets/images/products/exterior/Background Images/Background - Thermory_Deck- Flooring.jpg"),
            alt: "IPE deck flooring by Vikas Plywood"
        },
        { 
            src: require("../assets/images/products/exterior/Background Images/Thermory Pine_Exterior-Wall Cladding.jpg"),
            alt: "Exterior cladding from Thermory"
        },
        { 
            src: require("../assets/images/products/exterior/Background Images/Background - Thermory_Deck- Flooring.jpg"),
            alt: "IPE deck flooring by Vikas Plywood"
        },
        { 
            src: require("../assets/images/products/exterior/Background Images/Thermory Pine_Exterior-Wall Cladding.jpg"),
            alt: "Exterior cladding from Thermory"
        }
    ],
    'Adhesives': [
        { 
            src: require("../assets/images/products/adhesives/Vikas plywood_VIN grip_adhesives.webp"),
            alt: "VIN grip collection at Vikas Plywood"
        },
        { 
            src: require("../assets/images/products/adhesives/Vikas plywood_VIN grip_adhesives.webp"),
            alt: "VIN grip collection at Vikas Plywood"
        }
    ],
};


export const varietyMarqueeImages = {
    'Plywood': [
        'products/marque/1.png',
        'products/marque/2.png',
        'products/marque/3.png',
        'products/marque/4.png',
        'products/marque/5.png',
        'products/marque/6.png',

    ],
    'Veneers': [
        'products/marque/1.png',
        'products/marque/5.png',
        'products/marque/7.png',
        'products/marque/6.png',

    ],
    'Laminates': [
        'products/marque/8.png',
        'products/marque/9.png',
        'products/marque/10.png',
        'products/marque/11.png',
        'products/marque/cleaf.png',
        'products/marque/fenix.png',

    ],
    'Wooden Flooring': [
        'products/marque/12.png',
        'products/marque/13.png',
        'products/marque/14.png',
        'products/marque/6.png',
    ],
    'Wall Panels': [
        'products/marque/15.png',
        'products/marque/6.png'
    ],
    'Modular Interiors': [
        'products/marque/6.png'
    ],
    'Exterior Materials': [
        'products/marque/6.png',
    ],
    'Adhesives': [
        'products/marque/6.png',
    ],
};

export const laminatesimages = [
    { 
        src: require("../assets/images/products/laminates/Gallery/Saket image-01.webp"),
        alt: "Wood grain laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/Saket image-02.webp"),
        alt: "Wood grain laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/Saket image-03.webp"),
        alt: "Wood grain laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/1.jpg"),
        alt: "Wood grain laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/3.jpg"),
        alt: "Stone finish laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/4.jpg"),
        alt: "Wood grain laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/5.jpg"),
        alt: "Matte finish laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/6.jpg"),
        alt: "Stone finish laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/7.jpg"),
        alt: "Glossy finish laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/8.jpg"),
        alt: "Bison board"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/9.jpg"),
        alt: "Wood grain laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/10.jpg"),
        alt: "Wood grain laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/11.jpg"),
        alt: "Wood grain laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/12.jpg"),
        alt: "Laminate image 12"  
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/13.jpg"),
        alt: "Marble finish laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/18.jpg"),
        alt: "Wood grain laminate"
    },
    { 
        src: require("../assets/images/products/laminates/Gallery/21.jpg"),
        alt: "Wood grain laminate"
    }
];


export const veneersimages = [
    { 
        src: require("../assets/images/products/veneer/Gallery/1.jpeg"),
        alt: "A setting with a photo frame, a chair and crown cut veneer on the wall"
    },
    { 
        src: require("../assets/images/products/veneer/Gallery/2.jpeg"),
        alt: "Quarter cut veneer"
    },
    { 
        src: require("../assets/images/products/veneer/Gallery/3.jpeg"),
        alt: "Crown cut veneer in walnut"
    },
    { 
        src: require("../assets/images/products/veneer/Gallery/4.jpeg"),
        alt: "Quarter cut veneer in red oak"
    },
    { 
        src: require("../assets/images/products/veneer/Gallery/5.jpg"),
        alt: "Crown cut veneer in textured teak"
    },
    { 
        src: require("../assets/images/products/veneer/Gallery/6.jpg"),
        alt: "Crown cut veneer in textured teak"
    },
    { 
        src: require("../assets/images/products/veneer/Gallery/7.jpg"),
        alt: "Quarter cut veneer in walnut"
    }
];

export const woodenimages = [
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/1.jpg"),
        alt: "Light Walnut shade flooring in Chevron pattern"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/2.jpg"),
        alt: "Dark Walnut shade flooring in Chevron pattern"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/3.jpg"),
        alt: "Oak shade flooring in Herringbone pattern"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/4.jpg"),
        alt: "Oak shade flooring in Chevron pattern"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/5.jpg"),
        alt: "Geometric pattern wooden flooring by VIN"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/6.jpg"),
        alt: "Oak shade engineered flooring in plank pattern"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/7.jpg"),
        alt: "Oak shade engineered flooring in plank pattern"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/8.jpg"),
        alt: "Light Oak shade engineered flooring in plank pattern"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/9.jpg"),
        alt: "Grey colour designer wooden flooring"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/10.jpg"),
        alt: "Oak shade geometric wooden flooring paquette style"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/11.jpg"),
        alt: "Designer wood flooring by VIN"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/12.jpg"),
        alt: "Walnut shade geometric wooden flooring paquette style"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/13.jpg"),
        alt: "Walnut shade geometric wooden flooring paquette style"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/15.jpg"),
        alt: "Oak shade geometric wooden flooring paquette style"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/16.jpg"),
        alt: "Oak shade geometric wooden flooring paquette style"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/17.jpg"),
        alt: "Dark Walnut shade wood flooring in plank pattern"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/18.jpg"),
        alt: "Light Walnut shade wooden flooring in plank format"
    },
    { 
        src: require("../assets/images/products/wooden_flooring/Gallery/19.jpg"),
        alt: "Oak shade geometric wooden flooring paquette style"
    }
];
