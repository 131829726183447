import React, { useState } from 'react';
import EnquiryButton from '../../EnquiryButton';

export default function KitchenInside2({ category, motionType, link, imagestyle, path, variety }) {
  const formattedVariety = variety.replace(/\s+/g, '_').toLowerCase();

  return (

    <div
     
      className="relative overflow-hidden w-full h-full "
    >
      <h1 className="[font-family:'PP_Hatton-medium',Helvetica] m-[1vw] text-white absolute z-10 mt-2 mb-4 text-[2.3vw] max-md:text-[40px]">
        {variety}
      </h1>
      <div className='absolute right-0 bottom-0 z-10'>
      <EnquiryButton color={"white"} black={"black"} name ={variety} variety={path} />

      </div>
      <img
        className={`${imagestyle} max-md:h-[300px]   w-screen`}
        src={require(`../../../assets/images/products/kitchen/${link}/${formattedVariety}.JPG`)}
        alt="story"
      />
     
    </div>
  );
}
