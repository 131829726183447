import React from 'react'
import EnquiryButton from '../../EnquiryButton'

const ExteriorEnquiry = ({images,category, alt}) => {
  return (
    <div>
        <div className='m-[1.5vw] '>
    <img className='md:h-[60vh] h-[350px] w-screen object-cover' src={require(`../../../assets/images/products/exterior/${images}.jpg`)} alt={alt} />
    <div className='flex w-full items-center max-lg:mb-4 justify-between'>
      <h1 className="[font-family:'PP_Hatton-medium',Helvetica] whitespace-nowrap flex justify-center items-center font-bold   lg:leading-[4vw] text-[4vw] md:text-[2vw] text-black">
        {images} 
      </h1>
      <div className='w-[50%] '>
      <EnquiryButton name={images} variety={ `${category} from ExteriorMaterials`}/>

      </div>
    </div>
  </div>
  </div>
  )
}

export default ExteriorEnquiry