import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion';

const StyledBurger = styled.button`
  display: flex;
  position: relative;
  z-index: 3000;
  margin: auto 20px;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  transform: rotate(130deg);
  cursor: pointer;
  padding: 0;
  outline: none;

  div {
    width: 100%;
    height: 0.2rem;
    background: ${({ open, color }) => (open ? "black" : color)};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
/* 
  div:first-child {
    width: ${({ open }) => (open ? "2rem" : "1rem")};
    margin-left: ${({ open }) => (open ? "0px" : "10px")};
    transform: ${({ open }) => (open ? "rotate(0deg) " : "rotate(0)")};
    right: ${({ open }) => (open ? "12px" : "0")};
    top: ${({ open }) => (open ? "8px" : "0")};
  }


  div:last-child {
    transform: ${({ open }) => (open ? "rotate(-90deg) " : "rotate(0) ")};
    width: ${({ open }) => (open ? "2rem" : "1rem")};

  } */
  div:first-child {
    width: ${({ open }) => (open ? "2rem" : "1rem")};
    margin-left: ${({ open }) => (open ? "0px" : "10px")};
    transform: ${({ open }) => (open ? "rotate(45deg) " : "rotate(0)")};
    margin-bottom: ${({ open }) => (open ? "0.25rem" : "0")};
  }

  div:nth-child(2) {
    opacity: ${({ open }) => (open ? "0" : "1")};
    transform: ${({ open }) =>
      open
        ? "translateX(20px)"
        : "translateX(0)"}; /* Adjust the value for translation */
  }

  div:last-child {
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0) ")};
    width: ${({ open }) => (open ? "2rem" : "1rem")};
    margin-top: ${({ open }) => (open ? "0.25rem" : "0")};
  }
`;

const StyledMenu = styled.nav`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  right: 0;
  height: 100vh;
  top: 0;
  background: #f7f3ea;
  transition: transform 0.3s ease-in-out;
`;


const BurgerAndMenu = ({ color }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  const handleMenuClick = () => {
    setOpen(!open);
    toggleBodyScroll(!open);
  };

  const toggleBodyScroll = (disableScroll) => {
    const body = document.querySelector("body");
    if (disableScroll) {
      body.style.overflow = "hidden"; // Disable scrolling
    } else {
      body.style.overflow = "auto"; // Enable scrolling
    }
  };

  // Function to handle link clicks
  const handleLinkClick = () => {
    setOpen(false);
    toggleBodyScroll(false);
  };
  const LinkWithMotion = ({ to, onClick, onMouseEnter, onMouseLeave, children }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <Link
        to={to}
        onClick={onClick}
        onMouseEnter={() => {
          setIsHovered(true);
          onMouseEnter && onMouseEnter();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          onMouseLeave && onMouseLeave();
        }}
      >
        {children}
        {isHovered && (
          <motion.div
            initial={{ width: '5%' }}
            animate={{ width: isHovered ? '100%' : '5%' }}
            transition={{ duration: 0.7 }}
            style={{
              height: '1px',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'black',
            }}
          />
        )}
      </Link>
    );
  };
  
  return (
    <>
      <StyledBurger
        className=""
        open={open}
        onClick={handleMenuClick}
        color={color}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
      <StyledMenu className="w-full " open={open}>
        <header
          className={`fixed top-0 left-0 w-full flex  items-center justify-between px-4 py-2 logo-header  "
        } transition-all duration-300 ease-in-out`}
        >
          <Link to="/">
            <div className="flex items-center">
              <div className="mr-4">
                <img
                  src={require("../../assets/images/logomenu.png")}
                  alt="Logo"
                  className="w-[100px] max-md:hidden filter brightness-[200%]"
                />
              </div>
            </div>
          </Link>
        </header>

        <div>
        <div className="md:top-4 max-lg:hidden    w-full top-[-30px] relative max-lg:mx-[8vw] flex text-[40px] lg:text-[3vw] items-center justify-center   font-medium [font-family:'PP_Hatton-Medium',Helvetica] gap-[1vh]  z-50 flex-col  ">
            <LinkWithMotion
              to="/"
              onClick={handleLinkClick}
              className="w-full text-center relative "
            >
              Home
            </LinkWithMotion>

            <LinkWithMotion to="/about" onClick={handleLinkClick}>
              About
            </LinkWithMotion>
            <LinkWithMotion to="/products/Plywood" onClick={handleLinkClick}>
              Products
            </LinkWithMotion>
            <LinkWithMotion to="/vinboutique" onClick={handleLinkClick}>
              VIN Boutique
            </LinkWithMotion>
            <LinkWithMotion to="/projects" onClick={handleLinkClick}>
              Projects
            </LinkWithMotion>
            <LinkWithMotion to="/stories" onClick={handleLinkClick}>
              Stories
            </LinkWithMotion>
            <LinkWithMotion to="/Catalogues" onClick={handleLinkClick}>
            Catalogues
            </LinkWithMotion>
            <LinkWithMotion to="/contact" onClick={handleLinkClick}>
              Contact
            </LinkWithMotion>
          </div>
          <div className="md:top-4 lg:hidden top-[-30px] relative max-lg:mx-[8vw] flex text-[40px] lg:text-[3vw] items-center   font-medium [font-family:'PP_Hatton-Medium',Helvetica] gap-[1vh]  z-50 flex-col  ">
            <Link to="/" onClick={handleLinkClick} className="  ">
              Home
            </Link>
            <Link to="/about" onClick={handleLinkClick} className="  ">
              About
            </Link>
            <Link to="/products/Plywood" onClick={handleLinkClick} className="  ">
              Products
            </Link>
            <Link to="/vinboutique" onClick={handleLinkClick} className="  ">
              VIN Boutique
            </Link>
            <Link to="/projects" onClick={handleLinkClick} className="  ">
              Projects
            </Link>
            <Link to="/stories" onClick={handleLinkClick} className="  ">
              Stories
            </Link>
            <Link to="/Catalogues" onClick={handleLinkClick} className="  ">
            Catalogues
            </Link>
            <Link to="/contact" onClick={handleLinkClick} className="  ">
              Contact
            </Link>
          </div>
        </div>
      </StyledMenu>
    </>
  );
};

export default BurgerAndMenu;
