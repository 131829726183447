import React, { useState } from "react";

const EnquiryButton = ({ style, link, name,color, variety,black }) => {

    const handleEnquiry = () => {
        const phoneNumber = "+917093573311";
        const message = `I am interested in \n*${name}* of *${variety}*.\nPlease provide more information.`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappLink, '_blank');
    };


    return (
        <div className='' >
        <button style={{background:color, color:black}} onClick={handleEnquiry} className='bg-[#6d744c] font-serif md:text-[1.5vw] p-2 w-full text-white'>
              Enquire Now
            </button >
    </div >
  );
};

export default EnquiryButton;
