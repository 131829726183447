import React, { useState, useEffect } from 'react';
import { CgSpinner } from "react-icons/cg";
import { RiCloseLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { stateCityData } from '../utils/constant';
import emailjs from 'emailjs-com';

const FormPopup = ({ onClose, link }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        selection1: '',
        selectedState: '',
        selectedCity: '',
        termsAccepted: false,
        link: link,
    });

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.termsAccepted) {
            toast.error("Please accept the terms and conditions");
            return;
        }

        setLoading(true);

        const serviceID = 'service_dyi1mkc';
        const templateID = 'template_0ft68jm';
        const userID = 'r_8hIu516OevZppWV';

        emailjs.send(serviceID, templateID, formData, userID)
            .then(response => {
                setLoading(false);
                toast.success("Form submitted successfully!");
                window.open(`https://drive.google.com/uc?export=download&id=${link} `, '_blank');
                onClose();
            })
            .catch(error => {
                setLoading(false);
                toast.error("Failed to submit the form.");
                console.error('EmailJS Error:', error);
            });
    };

    return (
        <>
            <div className="popup-overlay w-full">
                <header className={`fixed top-0 left-0 w-full flex  items-center justify-between px-4 py-2  transition-all duration-300 ease-in-out`}>
                    <Link to="/">
                        <div className="flex items-center">
                            <div className="m-[1.3vw]">
                                <img
                                    src={require("../assets/images/logomenu.png")}
                                    alt="Logo"
                                    className="w-[100px] max-md:hidden filter brightness-[200%]"
                                />
                            </div>
                        </div>
                    </Link>
                    <button className=" md:text-[50px]  absolute mt-20 right-5 z-50 text-[40px] md:m-[4vw] font-thin" onClick={onClose}><RiCloseLine /></button>
                </header>
                <div className="w-full md:mx-[20vw]">
                    <div className=" relative z-[-10] ">
                        <h2 className=" text-center md:m-4 [font-family:'PP_Hatton-medium',Helvetica] md:text-[2vw] font-medium  text-[#000] relative  overflow-hidden ">
                            Please fill in the form to download the catalogue.
                        </h2>
                    </div>
                    <form className="w-[80%] mx-auto flex flex-col justify-center" onSubmit={handleSubmit}>
                        <div className='grid grid-cols-2 max-md:grid-cols-1'>
                            <div className='flex flex-col md:m-[1vw] m-[1px]'>
                                <label className="m-[0.1vw] text-[18px] " htmlFor="name">Full Name*</label>
                                <input className='bg-[#E7D9CC] p-3 border-[1px] border-black text-black placeholder:text-black border-solid'
                                    type="text" id="name" name="name" placeholder="Enter your full name" value={formData.name} onChange={handleInputChange} required />
                            </div>
                            <div className='flex flex-col md:m-[1vw] m-[1px]'>
                                <label className="m-[0.1vw] text-[18px]" htmlFor="email">Email Address*</label>
                                <input className='bg-[#E7D9CC] p-3 border-[1px] border-black text-black placeholder:text-black border-solid'
                                    type="email" id="email" name="email" placeholder="Enter your email ID" value={formData.email} onChange={handleInputChange} required />
                            </div>
                            <div className='flex flex-col md:m-[1vw] m-[1px]'>
                                <label className="m-[0.1vw] text-[18px]" htmlFor="phone">Mobile Number*</label>
                                <input className='bg-[#E7D9CC] p-3 border-[1px] border-black text-black placeholder:text-black border-solid'
                                    type="tel" id="phone" name="phone" placeholder="Enter your mobile number" minLength={10} value={formData.phone} onChange={handleInputChange} required />
                            </div>
                            <div className='flex flex-col md:m-[1vw] m-[1px]'>
                                <label className="m-[0.1vw] text-[18px]" htmlFor="selection1">You are*</label>
                                <select className='bg-[#E7D9CC] p-3 border-[1px] border-black text-black placeholder:text-black border-solid'
                                    id="selection1" name="selection1" value={formData.selection1} onChange={handleInputChange} required>
                                    <option value="">Select One</option>
                                    <option value="Architect">Architect</option>
                                    <option value="Interior Designer">Interior Designer</option>
                                    <option value="Contractor">Contractor</option>
                                    <option value="Homeowner">Homeowner</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className='flex flex-col md:m-[1vw] m-[1px]'>
                                <label className="m-[0.1vw] text-[18px]" htmlFor="selectedState">State*</label>
                                <select className='bg-[#E7D9CC] p-3 border-[1px] border-black text-black placeholder:text-black border-solid'
                                    id="selectedState"
                                    name="selectedState"
                                    value={formData.selectedState}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select State</option>
                                    {Object.keys(stateCityData).map((state, index) => (
                                        <option key={index} value={state}>{state}</option>
                                    ))}
                                </select>
                            </div>

                            <div className='flex flex-col md:m-[1vw] m-[1px]'>
                                <label className="m-[0.1vw] text-[18px]" htmlFor="selectedCity">City*</label>
                                <select className='bg-[#E7D9CC] p-3 border-[1px] border-black text-black placeholder:text-black border-solid'
                                    id="selectedCity"
                                    name="selectedCity"
                                    value={formData.selectedCity}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select City</option>
                                    {formData.selectedState &&
                                        stateCityData[formData.selectedState].map((city, index) => (
                                            <option key={index} value={city}>{city}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <label className='flex md:text-[0.8vw] text-[12px] mx-auto my-[2vw] md:max-w-[80%] items-start'>
                            <input type="checkbox" className='m-[0.2vw] bg-[#E7D9CC]' name="termsAccepted" checked={formData.termsAccepted} onChange={handleCheckboxChange} required />
                            By clicking the 'Submit' button, I agree to receive a call or message via WhatsApp or any other medium from Vikas Plywood.
                        </label>
                        <button type="submit" className={`bg-[#6d744c] mx-auto text-white justify-center flex font-serif md:text-[1.5vw] my-[1vw] p-2 px-[4vw] w-full ${loading ? 'disabled' : ''}`}>
                            {loading ? <CgSpinner size={20} className="spinner-icon" /> : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default FormPopup;
