import React from 'react'
import Header from '../components/Navbar/Navbar'
import BoutiqueMain from '../components/boutique/BoutiqueMain'
import { Helmet } from 'react-helmet';

const Boutique = () => {
  return (
    <div>
      <Helmet>
        <title>VIN Boutique - Vikas Plywood - One Stop Solution</title>
        <meta property="og:keywords" content="VIN, VIN grip, VIN adhesive, VIN veneers, VIN flooring, VIN profiles, VIN teak, Vikas plywood private label"/>
        <meta name="keywords" content="VIN, VIN grip, VIN adhesive, VIN veneers, VIN flooring, VIN profiles, VIN teak, Vikas plywood private label"/>
        <meta name="description" content="VIN is Vikas Plywood’s private label. Curating high-quality, unique products worldwide. From wooden flooring to veneers, discover excellence in design." />
        <meta name="og:image" content="https://www.vikasplywood.com/favicon.ico"/> 
      </Helmet>
      <Header logo="logomenu.png" color="black" />
      <BoutiqueMain />
    </div>
  )
}

export default Boutique