import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../../Navbar/Navbar';
import { useParams, Link,useNavigate } from 'react-router-dom';
import PdfEnquiry from '../PdfEnquiry';
import { Footer } from '../../Footer';
export default function VeneersComponent() {
    const {  variety } = useParams();
    console.log(variety)
    // const decodedVariety = variety.replace(/_/g, ' ').toUpperCase();
    const decodedVariety = variety .split("_")  .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    const navigate = useNavigate();

    const [zoomIn, setZoomIn] = useState(false);
    useEffect(() => {
        setZoomIn(true);
    }, []);
    useEffect(() => {
        const handleRouteChange = () => {
            setZoomIn(false);
        };
        const unlisten = () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
        window.addEventListener('popstate', handleRouteChange);
        return unlisten;
    }, []);
    const descriptionMap = {
        natural_veneers: `Natural unpolished veneers are slices of real wood that have not undergone a polishing or finishing process, preserving the wood's raw and organic appearance. These veneers are obtained from various hardwood trees, and their unpolished state highlights the authentic beauty and characteristics of the wood. Unpolished veneers are often more cost-effective than pre-finished alternatives, making them a budget-friendly option. <br/>  <br/> Select your preferred option from the brochures below and reach out to us. We'll take care of the rest for you.`,
        "pre-polished_veneers": `Pre-polished natural veneers refer to veneer sheets that have undergone a polishing or finishing process before being made available for use. The pre-polishing process enhances the overall finish quality of the veneer. It often results in a smooth, polished surface contributing to a refined and elegant appearance. While pre-polished veneers may be more expensive than raw or unfinished veneers, the time and effort saved in the finishing process can make them cost-effective in certain situations. 
        <br/> <br/>Choose your preferred option from the brochure provided below and get in touch with us. We'll take care of the rest for you.
        `,
    };

    const description = descriptionMap[variety] || '';

    const pdfVarieties = {
        natural_veneers: [
            { name: 'Greenlam Decowood', link: '1gruQCAjZEKlrP0FqRhrUX1pQzjkCgx4e' },
            { name: 'Greenply Woodcrest', link: '1dUQBDrSlbq9Xli3BLHBggkvAuXMAMsbO' },
            { name: 'Hunsur Veneers', link: '1ks3MwEtOe25vGZ__b-DhOjdfNVXotDde' },
            { name: 'VIN Natural Veneers', link: '1pqXjBstIoxPv1UFMxaDy63HShJWBn40R' },
            { name: 'Embossed Veneer', link: '1cnuF7gU57CZO5sPfhANjSOZ0JuZsKDVP' },

        ],
        "pre-polished_veneers": [
            { name: 'VIN Pre-polished Veneers', link: '1AgPHevN5dko0-vr1ttGWQdv86tSNpNYP' },
        ],
    };

    const selectedPdfs = pdfVarieties[variety] || [];
    return (
        <div>
            <motion.div
            >
                <div className={`z-[100] `}>
                    <Header logo="logomenu.png" color="black" />
                </div>
                <div className={`new-component   md:h-[80vh] h-[60vh] ${zoomIn ? 'zoom-in' : 'zoom-out'} relative`} style={{ position: 'relative' }}>
                    <h1 className="text-white whitespace-nowrap absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[8vw] md:text-[6vh]">
                        {decodedVariety}
                    </h1>
                    <img
                        className="overflow-hidden object-cover md:h-[80vh] h-[60vh] w-screen"
                        src={require(`../../../assets/images/products/veneer/${variety}.jpg`)}
                        alt="selected story"
                    />
                    <div className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}>
                        <Link onClick={() => navigate(-1)} to={`/products/Veneers`} >
                            <img  src={require("../../../assets/images/project/arrow.png")} alt="" />
                        </Link>
                    </div>
                    <div className='flex absolute text-white bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1'>
                        <Link to="/">
                            Home
                        </Link>
                        /
                        <Link to="/products/Veneers">
                            Products
                        </Link>
                        /
                        <p >
                        Veneers
                        </p>
                        /
                        <p  >
                            {decodedVariety}
                        </p>
                    </div>
                </div>
                <div className="relative w-full pb-[5vh]   bg-[#F7F3EA] text-black ">
                    <div className='mx-[3vw]' >
                    <div className="lg:max-w-[80%] border border-black md:text-[3vh] font-thin font-['Poppins'] my-10" dangerouslySetInnerHTML={{ __html: description }} />
                        <div className='bg-black h-[1px]' />
                        <div className='bg-black h-[1px]' />
                        <div className='grid border-2 border-red-500 md:grid-cols-2 grid-cols-1 '>
                            {selectedPdfs.map((pdf, index) => (
                                <PdfEnquiry key={index} variety={decodedVariety} category={"veneer"} name={pdf.name} link={pdf.link} />
                            ))}
                        </div>
                    </div>
                </div>
            </motion.div>
            <Footer/>
        </div>
    );
}
