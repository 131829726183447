import React, { useState } from 'react';

const ToggleSwitch = () => {

  return (
    <div className="flex items-center">
<label class="switch">
  <input  className='input' type="checkbox"/>
  <span class="slider round"></span>
</label>
    </div>
  );
};

export default ToggleSwitch;
