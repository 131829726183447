import React from "react";
import BoutiqueLink from "./BoutiqueLink";
import { Footer } from "../Footer";
export default function BoutiqueMain() {
  return (
    <div>
      <img
        src={require("../../assets/images/vin/banner.png")}
        className="md:h-[75vh] h-[60vh] w-screen"
      />
      <div className="md:mx-[3vw] mt-[2vw] mx-[5vw]">
        <div className="  max-md:mt-[20px] md:mb-14 flex max-md:justify-between   bg-[#f7f3ea] relative overflow-hidden ">
          <div>
            <div className="flex justify-between">

          
            <h1 className="md:flex  md:h-[114px] justify-start items-start [font-family:'PP_Hatton-medium',Helvetica] md:text-[45px] font-medium md:leading-[56.7px] text-[#000] relative text-left overflow-hidden z-[25] ">
              VIN: A Vikas Plywood <br />
              Private Label
            </h1>
            <img
              className="max-md:hidden  max-md:w-[50px] w-[8vw]  max-md:h-[50px]"
              src={require("../../assets/images/products/marque/6.png")}
              alt=""
/>
            </div>
            <div className="md:max-w-[80%] max-md:hidden md:text-[3vh] font-thin font-['Poppins'] md:my-10 ">
              VIN Boutique is Vikas Plywood’s private label. Our purpose is to
              source a unique range of high quality products from all over the
              world. We invest our R&D to find authentic manufacturing units
              that prioritise quality and fair trade.
              <br /> <br />
              From geometrical wooden flooring, pre-polished veneers, outdoor
              claddings, advanced adhesives and more, this collection is sure to
              catch your attention. Our endeavour is to continue bringing the
              best that the world of design offers to your doorstep. <br />
              <br />
              Learn more about our exclusive collection —
            </div>
          </div>
          <div>
          <img
              className=" md:hidden max-md:w-[50px] w-[8vw]  max-md:h-[50px]"
              src={require("../../assets/images/vin/header.png")}
              alt=""
            />
          </div>
        </div>
        <div className="bg-black md:hidden mt-[10px] mb-[20px] h-[2px]" />
        <div className="md:max-w-[700px] font-thin md:hidden font-['Poppins'] md:my-10 ">
          VIN Boutique is Vikas Plywood’s private label. Our purpose is to
          source a unique range of high quality products from all over the
          world. We invest our R&D to find authentic manufacturing units that
          prioritise quality and fair trade. From geometrical wooden flooring,
          pre-polished veneers, outdoor claddings, advanced adhesives and more,
          this collection is sure to catch your attention. Our endeavour is to
          continue bringing the best that the world of design offers to your
          doorstep.
          <br /> Learn more about our exclusive collection —
        </div>
        <h1 className="  max-md:mt-[4vw] md:my-[1vw] items-start [font-family:'PP_Hatton-medium',Helvetica] text-[25px]">
        Collection
        </h1>
        <div className="flex flex-col justify-center items-center">
          <div className="grid  grid-cols-1 md:grid-cols-3 gap-[2vw] ">
            <BoutiqueLink
              link="/products/Plywood/gurjan_plywood"
              variety="VIN Plywood"
              path="vin/1.png"
            />
            <BoutiqueLink
              link="/products/Veneers/pre-polished_veneers"
              variety="VIN Veneers"
              path="vin/2.png"
            />
            <BoutiqueLink
              link="/products/WallPanels/solid_wood_panels"
              variety="VIN Profiles"
              path="vin/3.png"
            />
            <BoutiqueLink
              link="/products/WoodenFlooring/engineered_wooden_flooring"
              variety="VIN Flooring"
              path="vin/4.png"
            />
            <BoutiqueLink
              link="/products/ExteriorMaterials/exterior_cladding"
              variety="VIN Teak"
              path="vin/5.png"
            />
            <BoutiqueLink
              link="/products/Adhesives"
              variety="VIN Grip"
              path="vin/6.png"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
