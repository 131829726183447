import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../Navbar/Navbar";
import { useParams, Link } from "react-router-dom";
import { Footer } from "../Footer";


export default function NewComponent({ }) {
    const { variety } = useParams();
    const [zoomIn, setZoomIn] = useState(false);
    useEffect(() => {
        setZoomIn(true);
    }, []);
    useEffect(() => {
        const handleRouteChange = () => {
            setZoomIn(false);
        };

        const unlisten = () => {
            window.removeEventListener("popstate", handleRouteChange);
        };
        window.addEventListener("popstate", handleRouteChange);

        return unlisten;
    }, []);

    const points2 = [
        `<strong>Elegance and Versatility: </strong> Wooden floors are available in a wide range of styles, grains, and finishes, making it easy to find the perfect fit for your home's aesthetic.`,
        `<strong>Durability: </strong> Hardwood floors are known for their long-lasting quality. When maintained properly, they can stand the test of time, maintaining their beauty for decades.`,
        `<strong>Easy Maintenance: </strong> Wooden floors are incredibly easy to clean and maintain. A quick sweep and occasional mopping are all it takes to keep them looking as good as new.`,
        `<strong>Budget Friendly: </strong> Laminate wooden floors offer a budget-friendly solution for those seeking the warmth and elegance of wood without the high cost associated with solid hardwood flooring.`
    ];
    const points5 = [
        `<strong>Time-saving: </strong> No more waiting for the polishing process to complete. Your project can be finished faster.`,
        `<strong>Consistent finish: </strong> Every panel guarantees a uniform, high-quality look. No more inconsistencies.`,
        `<strong>Easy Maintenance: </strong> Clean the sheet with any chemical based cleaner.`,
        `<strong>Reduced labor costs:</strong> Skip the extra manpower required for on-site polishing.`,
        `<strong>Enhanced durability:  </strong> The pre-applied finish protects the veneer from scratches and stains.`,
        `<strong>Fire retardant:  </strong> These veneers incorporate fire-retardant technology for added protection.`,
        `<strong>Immediate gratification:  </strong> Enjoy the luxurious look and feel of your space right away.`

    ];
    const points3 = [
        `<strong>Oak: </strong> Known for its strength and durability, oak is a timeless choice that complements both traditional and contemporary interiors.`,
        `<strong>Maple: </strong> With its fine, straight grain and light colour, maple creates a sense of spaciousness in any room.`,
        `<strong>Cherry: </strong> If you seek warmth and richness, cherry wood offers a luxurious touch that exudes elegance.    `,
        `<strong>Walnut: </strong> Dark and inviting, walnut wood floors add a touch of luxury and opulence to your space.`
    ];
    const points4 = [
        `<strong>Professional Installation: </strong> Hire experienced installers to ensure a flawless and long-lasting result.        `,
        `<strong>Regular Cleaning: </strong> Sweep or vacuum your floors daily to prevent the accumulation of dirt and debris.`,
        `<strong>Proper Protection: </strong> Use rugs or mats in high-traffic areas to minimise wear and tear.    `,
        `<strong>Refinishing: </strong> Every few years, consider refinishing your floors to keep them looking their best.        `
    ];



    const data = [
        {
            Aspect: "Material",
            Veneer: "Thin layer of real wood",
            Laminate: "Multiple layers of synthetic materials"
        },
        {
            Aspect: "Appearance",
            Veneer: "Natural wood beauty with grain, crown and burl patterns",
            Laminate: "Mimics various textures and patterns"
        },
        {
            Aspect: "Thickness",
            Veneer: "Comes in 4mm thickness",
            Laminate: "Thickness ranges from 0.72-1 mm"
        },
        {
            Aspect: "Applications",
            Veneer: "Furniture, cabinetry, interior doors, walls, ceilings",
            Laminate: "Countertops, cabinet surfaces, shutters"
        },
        {
            Aspect: "Durability",
            Veneer: "More susceptible to scratches, water stains",
            Laminate: "More durable and resistant to wear"
        },
        {
            Aspect: "Cost",
            Veneer: "Usually more expensive",
            Laminate: "More affordable"
        },
        {
            Aspect: "Aesthetic Preferences",
            Veneer: "Authentic beauty of real wood",
            Laminate: "Broad range of design options"
        },
        {
            Aspect: "Maintenance",
            Veneer: "May require more maintenance",
            Laminate: "Easier to clean and maintain"
        }
    ];
    return (
        <div>
            <motion.div className=" overflow-hidden">
                <div className={`z-[100] `}>
                    <Header logo="logomenu.png" color="black" />
                </div>
                <div
                    className={`new-component md:h-[80vh] h-[60vh] ${zoomIn ? "zoom-in" : "zoom-out"
                        } relative`}
                    style={{ position: "relative" }}
                >
                    <img
                        className="overflow-hidden  object-cover md:h-[80vh] h-[60vh] w-screen"
                        src={require(`../../assets/images/stories/Pictures/${variety}.webp`)}
                        alt="selected story"
                    />
                    <div
                        className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}
                    >
                        <Link to="/stories">
                            <img
                                src={require("../../assets/images/project/arrow.png")}
                                alt=""
                            />
                        </Link>
                    </div>

                </div>
                {variety === "Enhancing_Your_Home" && (
                    <div className="relative w-full font-thin font-['Poppins']  pb-[5vh] bg-[#F7F3EA] text-black">
                        <div className="mx-[3vw]">
                            <div className="lg:max-w-[90%] border border-black md:text-[3vh]  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    Enhancing Your Home With Exquisite Laminated Wooden Flooring
                                </h1>
                                <p>
                                    Laminated flooring has become an increasingly popular choice for homeowners who desire not only durability but also a touch of timeless elegance in their living spaces. From the living room to the bedroom, the warm and inviting nature of laminate floors adds an aura of sophistication that is hard to match with any other flooring material.
                                </p>
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    Why Choose Laminated Wooden Flooring?
                                </h1>
                                <p>
                                    Here are some compelling reasons why you should consider choosing laminated wooden flooring for your next project:
                                </p>
                            </div>
                            <div className=" flex">
                                <table className="w-full mb-[2vw] border-collapse md:text-[1.5vw]">
                                    <tbody>
                                        {points2.map((point, index) => (
                                            <tr key={index} className=" ">
                                                <td className=" px-4 py-2">
                                                    <span className="font-Poppins">•</span>
                                                </td>
                                                <td className=" px-4 py-2"
                                                    dangerouslySetInnerHTML={{ __html: point }}
                                                >
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <img className="lg:w-[65vw] md:h-[70vh] mx-auto" src={require("../../assets/images/stories/Pictures/Kronotex India_Vikas Plywood.webp")} alt="" />
                            <div className="lg:max-w-[90%] border border-black md:text-[3vh] font-thin  font-['Poppins'] my-10">

                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    The Aesthetic Appeal of Laminated Wooden Floors
                                </h1>
                                <p>
                                    Wooden flooring can seamlessly blend with various interior designs, enhancing the visual appeal of your home. Whether you prefer a classic, rustic look or a modern, minimalist style, wooden floors can adapt to your vision.
                                </p>
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    Popular Wood Choices
                                </h1>
                                <p>Some popular wood choices available in the industry are:
                                </p>
                            </div>

                            <div className=" flex">
                                <table className="w-full mb-[2vw] border-collapse md:text-[1.5vw]">
                                    <tbody>
                                        {points3.map((point, index) => (
                                            <tr key={index} className=" ">
                                                <td className=" px-4 py-2">
                                                    <span className="font-Poppins">•</span>
                                                </td>
                                                <td className=" px-4 py-2"
                                                    dangerouslySetInnerHTML={{ __html: point }}
                                                >
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="grid gap-[1.5vw] mt-5 grid-cols-2">
                                <img className="w-[50vw] h-[250px] md:h-[70vh]  mx-auto" src={require("../../assets/images/stories/Pictures/Kronotex_Vikas.webp")} alt="" />
                                <img className="w-[50vw]  h-[250px] md:h-[70vh] mx-auto" src={require("../../assets/images/stories/Pictures/Dynamic Series_Kronotex_Vikas Plywood.webp")} alt="" />
                            </div>
                            <div className="lg:max-w-[90%] border border-black md:text-[3vh] font-thin  font-['Poppins'] my-10">

                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    Installation and Maintenance
                                </h1>
                                <p>
                                    To ensure the longevity and beauty of your  floors, consider the following maintenance tips:
                                </p>
                                <table className="w-full mb-[2vw] border-collapse md:text-[1.5vw]">
                                    <tbody>
                                        {points4.map((point, index) => (
                                            <tr key={index} className=" ">
                                                <td className=" px-4 py-2">
                                                    <span className="font-Poppins">•</span>
                                                </td>
                                                <td className=" px-4 py-2"
                                                    dangerouslySetInnerHTML={{ __html: point }}
                                                >
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    Conclusion
                                </h1>
                                <p>In conclusion, laminated wooden flooring is a timeless and versatile choice that can transform your home into a welcoming and elegant space. With its durability, easy maintenance, affordability and aesthetic appeal, they are an investment that not only adds value to your property but also enriches your daily living experience.
                                </p> <br />
                                <p>Vikas Plywood offers a diverse selection of <Link className="text-blue-600" to={"/products/WoodenFlooring/laminated_wooden_flooring"} >laminate flooring</Link>  options tailored to suit various preferences. Whether you desire the flawless replication of wood grain or the genuine sensation of stone textures, we provide the ideal laminate flooring solution to meet any space or need. As the authorised reseller for Kronotex Germany throughout Telangana, we ensure access to high-quality products and exceptional customer service. <br /><Link className="text-blue-600" target="_blank" to={"https://wa.me/+917093573311"}>Get in touch today!</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {variety === "Veneer_vs_Laminate" && (
                    <div className="relative w-full font-thin font-['Poppins']  pb-[5vh] bg-[#F7F3EA] text-black">
                        <div className="mx-[3vw]">
                            <div className=" border border-black md:text-[3vh]  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    Veneer vs Laminate: Understanding the Distinctions
                                </h1>
                                <p className="lg:max-w-[90%]">
                                    When it comes to enhancing the look of furniture, cabinets, or countertops, <Link to="/products/Veneers" className="text-blue-600">veneer</Link> and <Link to={"/products/Laminates"} className="text-blue-600">laminate</Link> are two popular choices. They both offer cost-effective alternatives to solid wood or stone surfaces, but they differ significantly in terms of materials, appearance, and applications.
                                </p>


                            </div>


                            <div className="md:text-[2.3vh] flex text-[12px]  font-['Poppins'] ">
                                <div>
                                    <table>
                                        <thead>
                                            <tr className="font-medium text-left ">
                                                <th className="border-[2px] p-[1.5vw] border-solid border-black">Aspect</th>
                                                <th className="border-[2px] p-[1.5vw] border-solid border-black">Veneer</th>
                                                <th className="border-[2px] p-[1.5vw] border-solid border-black">Laminate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((row, index) => (
                                                <tr key={index}>
                                                    <td className="border-[2px] p-[1.5vw] border-solid border-black">{row.Aspect}</td>
                                                    <td className="border-[2px] p-[1.5vw] border-solid border-black">{row.Veneer}</td>
                                                    <td className="border-[2px] p-[1.5vw] border-solid border-black">{row.Laminate}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="lg:max-w-[90%] border border-black md:text-[3vh] font-thin  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    Conclusion
                                </h1>
                                <p>In conclusion, the choice between veneer and laminate depends on your specific needs and preferences. Veneer adds a touch of natural beauty, while laminate offers versatility and durability. Assess the demands of your project and your desired aesthetic to make the best decision for your furniture, cabinets, or surfaces.

                                </p> <br />
                                <p>The curated collection and experienced team at Vikas Plywood are here to guide you in making the optimal selection. Reach out to us today to make the right choice for your needs.
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {variety === "AGT_Wall_Panels" && (
                    <div className="relative w-full font-thin font-['Poppins']  pb-[5vh] bg-[#F7F3EA] text-black">
                        <div className="mx-[3vw]">
                            <div className=" border border-black md:text-[3vh]  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    AGT Wall Panels: Outshining Industry Alternatives with Unrivaled Quality
                                </h1>
                                <p className="lg:max-w-[90%]">
                                    In the ever-evolving world of interior design, wall paneling has emerged as a transformative element, seamlessly blending functionality with aesthetic appeal. As homeowners and architects alike seek to create spaces that reflect their unique style, the quest for quality wall paneling solutions has become paramount. This pursuit led us to <span className="text-blue-600"><Link target="_blank" to="https://www.agt.com.tr/en">AGT</Link></span>, a brand that stands as a paragon of excellence in the realm of interior paneling.
                                </p>
                            </div>
                            <div className=" border border-black md:text-[3vh]  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    The Quest for Quality
                                </h1>
                                <p className="lg:max-w-[90%]">
                                    When it comes to wall paneling solutions, not all products are created equal. In a market flooded with cheap imports from China and India, it's crucial to prioritize quality over cost-cutting measures that can compromise safety and durability. This is where AGT, a leading Turkish manufacturer, raises the bar with its exceptional decorative panel offerings.
                                </p>
                                <div className="grid gap-[1.5vw] mt-5 grid-cols-2">
                                    <img className="w-[50vw] object-cover h-[250px] md:h-[70vh] mx-auto" src={require("../../assets/images/stories/Pictures/agt1.webp")} alt="" />
                                    <img className="w-[50vw] object-cover  h-[250px] md:h-[70vh] mx-auto" src={require("../../assets/images/stories/Pictures/agt2.webp")} alt="" />
                                </div>
                            </div>
                            <div className=" border border-black md:text-[3vh]  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    The Perils of Hazardous Alternatives
                                </h1>
                                <p className="lg:max-w-[90%]">
                                    Many budget alternatives, such as charcoal panels, harbor a dark secret – the presence of toxic chemicals like lead and cyanide. These harmful substances can spread into the living spaces where you live, posing severe health risks and potentially causing organ diseases. Prioritizing cost savings at the expense of well-being is never a wise trade-off.
                                </p>

                            </div>
                            <div className=" border border-black md:text-[3vh]  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    A Paragon of European Excellence
                                </h1>
                                <p className="lg:max-w-[90%]">
                                    AGT wall panels are a testament to European craftsmanship and adherence to stringent quality standards. Manufactured in state-of-the-art facilities, these panels boast a perfect surface structure. Every panel is a masterpiece of superior quality and assurance.
                                </p>
                                <div className="grid gap-[1.5vw] mt-5 grid-cols-2">
                                    <img className="w-[50vw] object-cover  h-[250px] md:h-[70vh] mx-auto" src={require("../../assets/images/stories/Pictures/agt3.webp")} alt="" />
                                    <img className="w-[50vw] object-cover  h-[250px] md:h-[70vh] mx-auto" src={require("../../assets/images/stories/Pictures/agt4.webp")} alt="" />
                                </div>
                            </div>
                            <div className=" border border-black md:text-[3vh]  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    Unwavering Strength and Durability
                                </h1>
                                <p className="lg:max-w-[90%]">
                                    At the core of AGT's wall panels lies a secret weapon – High-Density Fiberboard (HDF) backing. This robust material ensures maximum resistance to bending and swelling, providing a solid foundation that withstands the test of time. Additionally, the high-quality PVC wrap offers exceptional durability and water resistance, safeguarding against wear and tear caused by moisture and chemical cleaners.
                                </p>
                            </div>

                            <div className=" border mt-[2vw]  border-black md:text-[3vh]  font-['Poppins'] max-sm:my-10 ">
                                <h1 className="font-medium  md:text-[3vw]">
                                    A Commitment to Healthier Living
                                </h1>
                                <div className="flex sm:flex-row flex-col justify-center w-full items-center max-sm:mt-5 ">
                                    <div className="sm:w-2/3 ">

                                        <p className="sm:w-[90%]" >

                                            AGT's dedication to quality extends beyond mere aesthetics; it encompasses a profound commitment to promoting healthier living environments. These profiles proudly bear the Greenguard Certification, a prestigious accolade that signifies their active contribution to creating healthier homes and a better future for all.
                                        </p>
                                    </div>

                                    <div className="sm:w-1/3 max-sm:mt-5   flex justify-center items-center">
                                        <img className=" md:h-[30vh] flex justify-center items-center h-[300px] mx-auto" src={require("../../assets/images/stories/Pictures/agt5.webp")} alt="" />

                                    </div>

                                </div>
                            </div>

                            <div className="lg:max-w-[90%] border border-black md:text-[3vh] font-thin  font-['Poppins'] max-sm:my-10 ">
                                <h1 className="font-medium max-sm:mt-[2vw] mb-[1vw] md:text-[3vw]">
                                    Conclusion
                                </h1>
                                <p>
                                    In the realm of interior paneling solutions, AGT stands as a beacon of excellence, prioritizing quality, beauty, and durability along with zero hazardous toxins. By choosing AGT, you're not only adorning your spaces with beauty; you're also investing in a future where superior craftsmanship and ethical practices reign supreme. <br /> Explore the <span className="text-blue-600"><Link target="_blank" to="https://www.vikasplywood.com/products/WallPanels/agt_wall_panels">AGT collection</Link></span> today, only at Vikas Plywood.
                                </p>

                            </div>
                        </div>
                    </div>
                )}
 {variety === "Why_Should_You_Use_Pre-Polished_Veneers_by_VIN" && (
                    <div className="relative w-full font-thin font-['Poppins']  pb-[5vh] bg-[#F7F3EA] text-black">
                        <div className="mx-[3vw]">
                            <div className="lg:max-w-[90%] border border-black md:text-[3vh]  font-['Poppins'] my-10">
                            <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">

Why Should You Use Pre-Polished Veneers by VIN?
</h1>
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                A Cut Above the Rest
                                </h1>
                              
                                
                                <p>
                                When it comes to transforming your space, veneers offer a world of possibilities. But not all veneers are created equal. Pre-polished veneers by VIN stand out as a game-changer in interior design, offering a perfect blend of elegance, durability, and convenience.
                                </p>

                            </div>
                            <img className="lg:w-[55vw] md:h-[70vh]  mx-auto" src={require("../../assets/images/stories/Pictures/polish1.webp")} alt="" />

                            <div className="lg:max-w-[90%] border border-black md:text-[3vh]  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                What Makes Pre-Polished Veneers Superior?
                                </h1>
                                <p>
                                Unlike traditional veneers that require additional polishing after installation, pre-polished veneers come ready-to-use, boasting a flawless finish straight out of the box. This translates to:
                                </p>
                              
                            </div>
                            <div className=" flex">
                                <table className="w-full mb-[2vw] border-collapse md:text-[1.5vw]">
                                    <tbody>
                                        {points5.map((point, index) => (
                                            <tr key={index} className=" ">
                                                <td className=" px-4 py-2">
                                                    <span className="font-Poppins">•</span>
                                                </td>
                                                <td className=" px-4 py-2"
                                                    dangerouslySetInnerHTML={{ __html: point }}
                                                >
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="lg:max-w-[90%] border border-black md:text-[3vh] font-thin  font-['Poppins'] my-10">
                                <h1 className="font-medium mt-[2vw] mb-[1vw] md:text-[3vw]">
                                Experience the VIN Difference
                                </h1>
                                <p>
                                At VIN Veneers, we pride ourselves on being Hyderabad's leading provider of <Link className=" text-blue-600" to={"/products/Veneers/pre-polished_veneers"} >pre-polished veneers. </Link> Our commitment to excellence is reflected in every sheet we produce. We understand that your space is a reflection of your style, and our veneers are designed to elevate your interior to new heights.
                                </p>
                               
                            </div>
                            <img className="lg:w-[55vw] md:h-[70vh]  mx-auto" src={require("../../assets/images/stories/Pictures/poliish5.webp")} alt="" />

                            <div className="lg:max-w-[90%] border border-black md:text-[3vh] font-thin  font-['Poppins'] my-10">
                            <p >
                                Whether you're designing a modern living room, a comfortable bedroom, or a prestigious office, VIN veneers will add a touch of magnificence to any space.
<br /><br /> <Link target="_blank" to={"https://www.google.com/maps/place/Vikas+Plywood+and+Veneers/@17.4303471,78.4040618,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb9733fea07e7f:0x4a00526f53c154e2!8m2!3d17.4303471!4d78.4040618!16s%2Fg%2F1jkw2p1ps?entry=ttu"} className="text-blue-600">Visit our showroom </Link> in Hyderabad or <Link to={"/contact"} className="text-blue-600"> contact us </Link> today to explore our collection of pre-polished veneers.
          </p>
                                </div>

                        </div>
                    </div>
                )}
            </motion.div>
            <Footer />
        </div>
    );
}