import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../../Navbar/Navbar';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { Footer } from '../../Footer';
import EnquiryButton from '../../EnquiryButton';
import VideoLoader from '../../about/VideoLoader';

const LinksList = ({ variety, variety2 }) => {
  const links = [
    { label: '231 Plain White', path: '/products/WallPanels/agt_wall_panels/3771/231_plain_white', variety: '231_plain_white' },
    { label: '239 Light Walnut', path: '/products/WallPanels/agt_wall_panels/3771/239_light_walnut', variety: '239_light_walnut' },
    { label: '248 Teak', path: '/products/WallPanels/agt_wall_panels/3771/248_teak', variety: '248_teak' },
    { label: '251 Tobacco', path: '/products/WallPanels/agt_wall_panels/3771/251_tobacco', variety: '251_tobacco' },
    { label: '369 German Oak', path: '/products/WallPanels/agt_wall_panels/3771/369_german_oak', variety: '369_german_oak' },
    { label: '376 New Oak', path: '/products/WallPanels/agt_wall_panels/3771/376_new_oak', variety: '376_new_oak' },
    { label: '723 Soft Touch Black', path: '/products/WallPanels/agt_wall_panels/3771/723_soft_touch_black', variety: '723_soft_touch_black' },
    { label: '3011 London Blues', path: '/products/WallPanels/agt_wall_panels/3771/3011_london_blues', variety: '3011_london_blues' },
  ];
  const links2 = [
    { label: '231 Plain White', path: '/products/WallPanels/agt_wall_panels/3783/231_plain_white', variety: '231_plain_white' },
    { label: '239 Light Walnut', path: '/products/WallPanels/agt_wall_panels/3783/239_light_walnut', variety: '239_light_walnut' },
    { label: '251 Tobacco', path: '/products/WallPanels/agt_wall_panels/3783/251_tobacco', variety: '251_tobacco' },
    { label: '726 Storm Grey', path: '/products/WallPanels/agt_wall_panels/3783/726_storm_grey', variety: '726_storm_grey' },
    { label: '728 Soft Touch Stone Grey', path: '/products/WallPanels/agt_wall_panels/3783/728_soft_touch_stone_grey', variety: '728_soft_touch_stone_grey' },
    { label: '729 Soft Touch New Grey', path: '/products/WallPanels/agt_wall_panels/3783/729_new_grey', variety: '729_new_grey' },
    { label: '735 Soft Touch Relax Green', path: '/products/WallPanels/agt_wall_panels/3783/735_soft_touch_relax_green', variety: '735_soft_touch_relax_green' },
    { label: '3011 London Blues', path: '/products/WallPanels/agt_wall_panels/3783/3011_london_blues', variety: '3011_london_blues' },
  ];
  const links3 = [
    { label: '231 Plain White', path: '/products/WallPanels/agt_wall_panels/2250/231_plain_white', variety: '231_plain_white' },
    { label: '237 Maple White', path: '/products/WallPanels/agt_wall_panels/2250/237_maple_white', variety: '237_maple_white' },
    { label: '239 Light Walnut', path: '/products/WallPanels/agt_wall_panels/2250/239_light_walnut', variety: '239_light_walnut' },
    { label: '248 Teak', path: '/products/WallPanels/agt_wall_panels/2250/248_teak', variety: '248_teak' },
    { label: '251 Tobacco', path: '/products/WallPanels/agt_wall_panels/2250/251_tobacco', variety: '251_tobacco' },
    { label: '375 Arizona Oak', path: '/products/WallPanels/agt_wall_panels/2250/375_arizona_oak', variety: '375_arizona_oak' },
    { label: '726 Soft Touch Storm Grey', path: '/products/WallPanels/agt_wall_panels/2250/726_soft_touch_storm_grey', variety: '726_soft_touch_storm_grey' },
    { label: '735 Soft Touch Relax Green', path: '/products/WallPanels/agt_wall_panels/2250/735_soft_touch_relax_green', variety: '735_soft_touch_relax_green' },
    { label: '3011 London Blues', path: '/products/WallPanels/agt_wall_panels/2250/3011_london_blues', variety: '3011_london_blues' },
  ];
  const links4 = [
    { label: '231 Plain White', path: '/products/WallPanels/agt_wall_panels/2050/231_plain_white', variety: '231_plain_white' },
    { label: '248 Teak', path: '/products/WallPanels/agt_wall_panels/2050/248_teak', variety: '248_teak' },
    { label: '251 Tobacco', path: '/products/WallPanels/agt_wall_panels/2050/251_tobacco', variety: '251_tobacco' },
    { label: '369 German Oak', path: '/products/WallPanels/agt_wall_panels/2050/369_german_oak', variety: '369_german_oak' },

  ];
  return (
    <div>
      {variety2 === "3771" && (
        <>
          {links.map((link, index) => (
            <React.Fragment key={index}>
              {link.variety === variety && (
                <div className="relative top-[0.8vw] right-[4vw]">
                  <img
                    className="absolute"
                    src={require("../../../assets/images/products/wall/agt/Arrow2.png")}
                    alt=""
                  />
                </div>
              )}
              <Link
                to={link.path}
                className="hover:font-bold w-full md:text-[3vh] transition-all duration-100"
              >
                {link.label}
              </Link>
              <div className="h-[1px] bg-black" />
            </React.Fragment>
          ))}
        </>
      )}
      {variety2 === "3783" && (
        <>
          {links2.map((link, index) => (
            <React.Fragment key={index}>
              {link.variety === variety && (
                <div className="relative top-[0.8vw] right-[4vw]">
                  <img
                    className="absolute"
                    src={require("../../../assets/images/products/wall/agt/Arrow2.png")}
                    alt=""
                  />
                </div>
              )}
              <Link
                to={link.path}
                className="hover:font-bold w-full md:text-[3vh] transition-all duration-100"
              >
                {link.label}
              </Link>
              <div className="h-[1px] bg-black" />
            </React.Fragment>
          ))}
        </>
      )}
      {variety2 === "2250" && (
        <>
          {links3.map((link, index) => (
            <React.Fragment key={index}>
              {link.variety === variety && (
                <div className="relative top-[0.8vw] right-[4vw]">
                  <img
                    className="absolute"
                    src={require("../../../assets/images/products/wall/agt/Arrow2.png")}
                    alt=""
                  />
                </div>
              )}
              <Link
                to={link.path}
                className="hover:font-bold w-full md:text-[3vh] transition-all duration-100"
              >
                {link.label}
              </Link>
              <div className="h-[1px] bg-black" />
            </React.Fragment>
          ))}
        </>
      )}
      {variety2 === "2050" && (
        <>
          {links4.map((link, index) => (
            <React.Fragment key={index}>
              {link.variety === variety && (
                <div className="relative top-[0.8vw] right-[4vw]">
                  <img
                    className="absolute"
                    src={require("../../../assets/images/products/wall/agt/Arrow2.png")}
                    alt=""
                  />
                </div>
              )}
              <Link
                to={link.path}
                className="hover:font-bold w-full md:text-[3vh] transition-all duration-100"
              >
                {link.label}
              </Link>
              <div className="h-[1px] bg-black" />
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

export default function WallInside2() {
  const navigate = useNavigate();
  const { image, variety3, variety2 } = useParams();
  const stringWithSpaces = variety3.replace(/_/g, ' ');
  const capitalizedString = stringWithSpaces.replace(/\b\w/g, (char) => char.toUpperCase());
  const convertedString = capitalizedString.replace(/(\d+)\s(.*)/, '$1 - $2');
  const [isLoading, setIsLoading] = useState(false);
  const decodedVariety = variety3
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" "); const [zoomIn, setZoomIn] = useState(false);
  const [currentImage, setCurrentImage] = useState(1);
  const [linePosition, setLinePosition] = useState('left');
  const handleClickImage = () => {
    setIsLoading(true);
    setCurrentImage(currentImage === 1 ? 2 : 1);
    setLinePosition(linePosition === 'left' ? 'right' : 'left');
  };
  useEffect(() => {
    setZoomIn(true);
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setZoomIn(false);
    };

    const unlisten = () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
    window.addEventListener('popstate', handleRouteChange);
    return unlisten;
  }, []);
  const [transformOrigin, setTransformOrigin] = useState(null);
  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setTransformOrigin(`${x}px ${y}px`);
  };

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500); // Adjust this time as needed
      return () => clearTimeout(timer);
    }
  }, [isLoading]);
  return (
    <div className=' overflow-hidden'>
      <div className={`z-[100] `}>
        <Header logo="logomenu.png" color="black" />
      </div>
          <div className={`new-component h-[60vh] ${zoomIn ? 'zoom-in' : 'zoom-out'} relative`} style={{ position: 'relative' }}>
            <h1 className={` ${(variety3 === '723_soft_touch_black' || variety3 === '3011_london_blues' || variety3 === '726_storm_grey' || variety3==='726_soft_touch_storm_grey') ? 'text-[#F7F3EA]' : 'text-black'} text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vh]`}>
              {decodedVariety}
            </h1>
            <img
              className="overflow-hidden object-center h-[80vh] w-screen"
              src={require(`../../../assets/images/products/wall/Product Photos/${variety2}/${convertedString}/color.jpg`)}
              alt="selected story"
            />
            <div className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}>
            <Link className={(variety3 === '723_soft_touch_black' || variety3 === '3011_london_blues' || variety3 === '726_storm_grey' || variety3==='726_soft_touch_storm_grey') ? 'invert' : 'invert-0'} to={`/products/WallPanels/agt_wall_panels/${variety2}`}>
            <img
                  src={require("../../../assets/images/project/arrow.png")}
                  alt=""
                />
              </Link>
            </div>
            <div className={`flex absolute ${(variety3 === '723_soft_touch_black' || variety3 === '3011_london_blues' || variety3 === '726_storm_grey' || variety3==='726_soft_touch_storm_grey') ? 'text-[#F7F3EA]' : 'text-black'}  bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1`}>
              <Link to="/">
                Home
              </Link>
              /
              <Link to="/products/WallPanels">
                Products
              </Link>
              /
              <Link to="/products/WallPanels">
                WallPanels
              </Link>
              /
              <p  >
                {decodedVariety.split(' ')[0]}
              </p>
            </div>
          </div>
      <div className="relative overflow-hidden w-full  bg-[#F7F3EA] text-black ">
        <div className='mx-[3vw] flex justify-center md:justify-between gap-[2vw] ' >
          <div className='flex overflow-hidden md:flex-row flex-col gap-[2vw] justify-center md:justify-between'>
            <div className="border overflow-hidden justify-center mx-auto flex md:flex-row flex-col border-black text-[3vh] font-medium font-['Poppins'] max-md:my-10 mt-10">
              <div className="relative overflow-hidden">
                <div className="relative">
                  <img
                    className="w-[60vh] h-[40vh] cursor-pointer lg:h-[65vh]"
                    src={require(`../../../assets/images/products/wall/Product Photos/${variety2}/${convertedString}/${currentImage}.jpg`)}
                    alt=""
                    onClick={handleClickImage}
                  />
                  {isLoading && <VideoLoader />}
                  <div
                    className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out"
                    onMouseMove={(e) => handleMouseMove(e)}
                    onMouseLeave={() => setTransformOrigin(null)}
                  >
                    <div onClick={handleClickImage} className="w-full h-full overflow-hidden">
                      <img
                        className="w-full h-full object-cover transform lg:hover:scale-150 transition-transform duration-300 ease-in-out"
                        src={require(`../../../assets/images/products/wall/Product Photos/${variety2}/${convertedString}/${currentImage}.jpg`)}
                        alt=""
                        style={{ transformOrigin: transformOrigin }}
                      />
                    </div>
                  </div>
                </div>
                <div onClick={handleClickImage} className="w-[7vw] h-[5px] cursor-pointer mt-[1vw] mx-auto relative">
                  <div
                    className={`absolute bottom-0 ${linePosition === 'left' ? 'left-0' : 'right-0'} w-1/4 border-b-[1px] border-solid border-black`}
                  />
                  <div
                    className={`absolute bottom-0 ${linePosition === 'left' ? 'right-0' : 'left-0'} w-2/4 border-b-[2px] border-solid border-black`}
                  />
                </div>
              </div>

              <div className=' px-[2vw] md:w-[30vw] flex overflow-hidden md:ml-[5vw] flex-col mx-[2vw] max-md:my-10 mt-10  gap-[1vw]'>
                <h1 className="[font-family:'PP_Hatton-medium',Helvetica]   font-medium mb-[1vw] text-[10vw]  md:text-[5vh] text-black " > Dimensions:</h1>
                <h1>
                  <p className="[font-family:'Poppins-Light',Helvetica] w-full justify-between  flex   font-normal md:text-[1.5vw] text-[5vw] max-md:my-2">
                    <p className='font-bold'>Height:</p>110.2in/2800mm <br />
                  </p>
                  <p className="[font-family:'Poppins-Light',Helvetica] w-full justify-between  flex   font-normal md:text-[1.5vw] text-[5vw] max-md:my-2">
                    <p className='font-bold'>Width:</p>
                    {variety2 === "3771" && (
                      <>
                        4.8 in/121mm
                      </>
                    )}
                    {variety2 === "3783" && (
                      <>
                        6.2 in/158mm
                      </>
                    )}
                    {variety2 === "2250" && (
                      <>
                        4.5 in/115mm
                      </>
                    )}
                    {variety2 === "2050" && (
                      <>
                        4.8 in/121mm
                      </>
                    )}
                    <br />
                  </p>
                  <p className="[font-family:'Poppins-Light',Helvetica] w-full justify-between  flex   font-normal md:text-[1.5vw] text-[5vw] max-md:my-2">
                    <p className='font-bold'>Thickness:</p>
                    {variety2 === "3771" && (
                      <>
                        18mm
                      </>
                    )}
                    {variety2 === "3783" && (
                      <>
                        12mm
                      </>
                    )}
                    {variety2 === "2250" && (
                      <>
                        12mm
                      </>
                    )}
                    {variety2 === "2050" && (
                      <>
                        18mm
                      </>
                    )}

                    <br />
                  </p>
                  <p className="[font-family:'Poppins-Light',Helvetica] w-full justify-between  flex   font-normal md:text-[1.5vw] text-[5vw] max-md:my-2">
                    <p className='font-bold'>Sqft:</p>
                    {variety2 === "3771" && (
                      <>
                        3.65 sqft.
                      </>
                    )}
                    {variety2 === "3783" && (
                      <>
                        4.75 sqft.
                      </>
                    )}
                    {variety2 === "2250" && (
                      <>
                        3.1 sqft
                      </>
                    )}
                    {variety2 === "2050" && (
                      <>
                        4.2 sqft.
                      </>
                    )}
                    <br />
                  </p>
                  <p className="[font-family:'Poppins-Light',Helvetica] w-full justify-between  flex   font-normal md:text-[1.5vw] text-[5vw] max-md:my-2">
                    <p className='font-bold'>Finish:</p>
                    {variety2 === "2050" ? (
                      <>
                        Rough
                      </>
                    ) : (
                      <>
                        Rough /Soft
                      </>
                    )
                    }
                    <br />
                  </p>
                  <p className="[font-family:'Poppins-Light',Helvetica] w-full justify-between  flex   font-normal md:text-[1.5vw] text-[5vw] max-md:my-2">
                    <p className='font-bold'>Fixing system :</p>
                    {variety2 === "3771" ? (
                      <>
                        Overlapping
                      </>
                    ) : (
                      <>
                        Interlocking
                      </>
                    )
                    }
                    <br />
                  </p>
                </h1>

                <EnquiryButton name={decodedVariety} variety={`${variety2} from AGT Wall Panels`} />
              </div>
            </div>
            <div className=' max-md:hidden    flex flex-col mx-[2vw] max-md:my-10 mt-10 items-center  justify-between '>
              <h className="[font-family:'PP_Hatton-medium',Helvetica] md:ml-[3vw] md:mt-[2vw]  font-medium mb-[1vw]   lg:text-[2vw] text-black">
                <p className='text-[4vh]'> {variety2}</p>
                <div className='h-[1px] bg-black' />
                <LinksList variety2={variety2} variety={variety3} />
              </h>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
