import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function WallLInk2({ category, alt, motionType, link, imagestyle, hidden, variety }) {
    const formattedVariety = variety.replace(/\s+/g, '_').toLowerCase();
    console.log(formattedVariety, variety, "hello")
    const [isHovered, setIsHovered] = useState(false);
    const handleHoverStart = () => {
        setIsHovered(true);
    };

    const handleHoverEnd = () => {
        setIsHovered(false);
    };

    return (
        <div className='pb-[2vw] max-md:mb-6 '>
            <div
                onMouseEnter={handleHoverStart}
                onMouseLeave={handleHoverEnd}
                className="relative overflow-hidden w-full h-full"
            >
                <img
                    className={`${imagestyle} w-screen`}
                    src={require(`../../../assets/images/products/${link}`)}
                    alt={alt}
                />
                <div className='max-lg:hidden'>
                    <motion.div
                        className={`absolute bottom-0 left-0 w-full h-full flex justify-center items-center ${isHovered ? 'block' : 'hidden'}`}
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: isHovered ? 1 : 0, scale: isHovered ? 1 : 1 }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                        style={{ backgroundColor: 'rgba(246, 246, 246, 0.5)' }}
                    >
                        <div className="p-4 text-black flex text-center">
                            <h1 className="[font-family:'PP_Hatton-medium',Helvetica]  text-center text-[4.5vh]">
                                {variety}
                            </h1>
                        </div>
                    </motion.div>
                </div>
            </div>
            <h1 className="font-['Poppins'] absolute mb-[2vw] ">
                {variety}
            </h1>
        </div>


    );
}
