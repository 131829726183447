import React, { useEffect, useRef } from 'react';
import Founder from './Founder';
import PictureAndTextComponent from './PictureAndTextComponent';

const tailwind = "sticky top-0 h-auto ";
const MainHero = () => {
    const godown = () => {
        document.getElementById("storiess").scrollIntoView({ behavior: "smooth" });
    };
    return (
        <div >
            <React.Fragment>
                <div onClick={godown} className={` aboutpicture ${tailwind} z-[3]`}>
                    <img className='h-screen max-md:hidden w-screen' src="./about.webp" alt="A birch plywood wall describing the brand story of Vikas Plywood" />
                </div>
            </React.Fragment>
            <React.Fragment>
                <div id="storiess" className={` aboutpicturee ${tailwind} z-[4]`}>
                    <PictureAndTextComponent />
                </div>

            </React.Fragment>
            <React.Fragment>
                <div
                    className={`relative h-screen z-[1] ${tailwind}`}
                >
                </div>
            </React.Fragment>
            <React.Fragment>
                <div
                    className='relative z-[5]'
                >
                    <Founder />
                </div>
            </React.Fragment>
        </div>
    );
};

export default MainHero;