import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function ProductsLink({ link,alt, motionType, arrow, imagestyle, path, hidden, variety }) {
    const formattedVariety = variety.replace(/\s+/g, '_').toLowerCase();
    const [isHovered, setIsHovered] = useState(false);

    const handleHoverStart = () => {
        setIsHovered(true);
    };

    const handleHoverEnd = () => {
        setIsHovered(false);
    };

    return (
        <Link
            to={`${formattedVariety}`}
            state={{ arrow }}
            rel="noopener noreferrer"
        >
            <div
                onMouseEnter={handleHoverStart}
                onMouseLeave={handleHoverEnd}
                className="relative overflow-hidden w-full h-full"
            >
                <img
                    className={`${imagestyle} md:h-[60vh] max-md:object-cover h-[300px] w-screen`}
                    src={require(`../../../assets/images/products/${link}/${formattedVariety}.jpg`)}
                    alt={alt}
                />
                 <h1 className="[font-family:'PP_Hatton-medium',Helvetica] lg:hidden mt-2 mb-4 text-[20px]">
                                {variety}
                            </h1>
                <div className='max-lg:hidden'>
                    {motionType === "motion1" ? (
                    <motion.div
                        className={`absolute bottom-0 left-0 w-full h-full flex justify-start items-end ${isHovered ? 'block' : 'hidden'}`}
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: isHovered ? 1 : 0, scale: isHovered ? 1 : 1 }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                        style={{ backgroundColor: 'rgba(246, 246, 246, 0.5)' }}
                    >
                        <div className="p-4 text-black flex text-start">
                            <h1 className="[font-family:'PP_Hatton-medium',Helvetica] text-[4.5vh]">
                                {variety}
                            </h1>
                            <h1 className={`${hidden}`}>
                                <svg
                                    className="w-[30px] h-[30px]"
                                    fill="none"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.9199 7.12C17.8185 6.87565 17.6243 6.68147 17.3799 6.58C17.2597 6.52876 17.1306 6.50158 16.9999 6.5H6.99994C6.73472 6.5 6.48037 6.60536 6.29283 6.79289C6.1053 6.98043 5.99994 7.23478 5.99994 7.5C5.99994 7.76522 6.1053 8.01957 6.29283 8.20711C6.48037 8.39464 6.73472 8.5 6.99994 8.5H14.5899L6.28994 16.79C6.19621 16.883 6.12182 16.9936 6.07105 17.1154C6.02028 17.2373 5.99414 17.368 5.99414 17.5C5.99414 17.632 6.02028 17.7627 6.07105 17.8846C6.12182 18.0064 6.19621 18.117 6.28994 18.21C6.3829 18.3037 6.4935 18.3781 6.61536 18.4289C6.73722 18.4797 6.86793 18.5058 6.99994 18.5058C7.13195 18.5058 7.26266 18.4797 7.38452 18.4289C7.50638 18.3781 7.61698 18.3037 7.70994 18.21L15.9999 9.91V17.5C15.9999 17.7652 16.1053 18.0196 16.2928 18.2071C16.4804 18.3946 16.7347 18.5 16.9999 18.5C17.2652 18.5 17.5195 18.3946 17.707 18.2071C17.8946 18.0196 17.9999 17.7652 17.9999 17.5V7.5C17.9984 7.36932 17.9712 7.24022 17.9199 7.12Z"
                                        fill="#fdfdfd"
                                    />
                                </svg>
                            </h1>
                        </div>
                    </motion.div>
                ) : motionType === "motion2" ? (
                    <motion.div
                        className={`absolute bottom-0 left-0 w-full h-full flex justify-end items-center ${isHovered ? 'block' : 'hidden'}`}
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: isHovered ? 1 : 0, scale: isHovered ? 1 : 1 }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                        style={{ backgroundColor: 'rgba(246, 246, 246, 0.5)' }}
                    >
                        <div className="p-4 text-black flex text-center">
                            <h1 className="[font-family:'PP_Hatton-medium',Helvetica] mr-[5vw] text-center text-[9.5vh]">
                                {variety}
                            </h1>
                        </div>
                    </motion.div>
                ) : (
                    <motion.div

                        className={`absolute bottom-0 left-0 w-full h-full flex justify-start items-end ${isHovered ? 'block' : 'hidden'}`}
                        initial={{ opacity: 0, y: '100%' }}
                        animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? '0%' : '100%' }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
                    >
                        <div className="p-4 text-white flex text-start">
                            <h1 className="[font-family:'PP_Hatton-medium',Helvetica] text-[4.5vh]">
                                {variety}
                            </h1>
                            <h1>
                                <svg
                                    className="w-[30px] h-[30px]"
                                    fill="none"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.9199 7.12C17.8185 6.87565 17.6243 6.68147 17.3799 6.58C17.2597 6.52876 17.1306 6.50158 16.9999 6.5H6.99994C6.73472 6.5 6.48037 6.60536 6.29283 6.79289C6.1053 6.98043 5.99994 7.23478 5.99994 7.5C5.99994 7.76522 6.1053 8.01957 6.29283 8.20711C6.48037 8.39464 6.73472 8.5 6.99994 8.5H14.5899L6.28994 16.79C6.19621 16.883 6.12182 16.9936 6.07105 17.1154C6.02028 17.2373 5.99414 17.368 5.99414 17.5C5.99414 17.632 6.02028 17.7627 6.07105 17.8846C6.12182 18.0064 6.19621 18.117 6.28994 18.21C6.3829 18.3037 6.4935 18.3781 6.61536 18.4289C6.73722 18.4797 6.86793 18.5058 6.99994 18.5058C7.13195 18.5058 7.26266 18.4797 7.38452 18.4289C7.50638 18.3781 7.61698 18.3037 7.70994 18.21L15.9999 9.91V17.5C15.9999 17.7652 16.1053 18.0196 16.2928 18.2071C16.4804 18.3946 16.7347 18.5 16.9999 18.5C17.2652 18.5 17.5195 18.3946 17.707 18.2071C17.8946 18.0196 17.9999 17.7652 17.9999 17.5V7.5C17.9984 7.36932 17.9712 7.24022 17.9199 7.12Z"
                                        fill="#fdfdfd"
                                    />
                                </svg>
                            </h1>
                        </div>
                    </motion.div>
                )}  
                </div>
              
            </div>
        </Link>
    );
}
