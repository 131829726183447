import './App.css';
import { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import { Catalogues } from './pages/Catalogues';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import Projects from './pages/Projects';
import Boutique from './pages/Boutique';
import Stories from './pages/Stories';
import Products from './pages/Products';
import NewComponent from './components/Stories/NewComponent';
import WallComponent from './components/products/variety/WallComponent';
import WallInside from './components/products/variety/WallInside';
import WallInside2 from './components/products/variety/WallInside2';
import KitchensComponent from './components/products/variety/KitchensComponent';
import KitchenInside from './components/products/variety/KitchenInside';
import KitchenInside2 from './components/products/variety/KitchenInside2';
import ImageComponent2 from './components/boutique/ImageComponent2';
import ImageComponent from './components/Project/ImageComponent';
import PlywoodComponent from './components/products/variety/PlywoodComponent';
import VeneersComponent from './components/products/variety/VeneersComponent';
import WoodenComponent from './components/products/variety/WoodenComponent';
import ExteriorComponent from './components/products/variety/ExteriorComponent';
import AdhesivesComponent from './components/products/variety/AdhesivesComponent';
import CustomCursor from './pages/CustomCursor';
import Privacy from './pages/Privacy';
import LogoLoader from './pages/LogoLoader';
import { Helmet } from 'react-helmet';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const images = [
    require("./assets/images/home/Property1=Group148.webp"),
    require("./assets/images/home/Property1=Group145.webp"),
    require("./assets/images/home/Property1=Group144.webp"),
    require("./assets/images/home/Property1=Group147.webp"),
    require("./assets/images/home/Property1=Group146.webp"),
  ];
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timer;
    const loadImages = async () => {
      const promises = images.map((image) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = resolve;
          img.onerror = reject;
          img.src = image;
        });
      });

      try {
        await Promise.all(promises);
      } catch (err) {
        console.error('Failed to load images:', err);
      } finally {
        clearTimeout(timer);
        setIsLoading(false);
      }
    };

    timer = setTimeout(() => {
      loadImages();
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [images]);

  return (
    <>
      {isLoading ? (
        <div><LogoLoader /></div>
      ) : (
        <Router>
          <Helmet>
            <script type="application/ld+json">
              {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Vikas Plywood",
              "url": "https://www.vikasplywood.com",
              "logo": "https://www.vikasplywood.com/logo.ico",
              "description": "Family-owned, guided by vision & values of respect and familial ethics. We believe in growing together.",
              "address": {  
                "@type": "PostalAddress",
                "streetAddress": "123 Main Street",
                "addressLocality": "Hyderabad",
                "addressRegion": "Telangana",
                "postalCode": "500001",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-7093573311",
                "contactType": "customer service",
                "areaServed": "IN"
              },
              "sameAs": [
                "https://www.facebook.com/vikasplywood",
                "https://twitter.com/vikasplywood",
                "https://www.linkedin.com/company/vikasplywood"
              ]
            }
          `}
            </script>
            <meta name="description" content="Vikas Plywood is a one-stop interior and exterior solutions company in Hyderabad. We offer a wide range of materials like plywood, veneers, laminates, wooden flooring, AGT wall panels, modular kitchens & wardrobes, and more. Discover global design trends accessible to India's evolving architectural landscape." />
            <title>Vikas Plywood - One Stop Solution</title>
          </Helmet>
          <ScrollToTop />
          <CustomCursor />
          <Routes>
            <Route path="/Catalogues" element={<Catalogues />} />
            <Route path="/privacypolicy" element={<Privacy />} />
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/vinboutique" element={<Boutique />} />
            <Route path="/vinboutique/:variety" element={<ImageComponent2 />} />
            <Route path="/projects/:variety" element={<ImageComponent />} />
            <Route path="/stories" element={<Stories />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:category" element={<Products />} />
            <Route path="/products/Plywood/:variety" element={<PlywoodComponent />} />
            <Route path="/products/Veneers/:variety" element={<VeneersComponent />} />
            <Route path="/products/WoodenFlooring/:variety" element={<WoodenComponent />} />
            <Route path="/products/ExteriorMaterials/:variety" element={<ExteriorComponent />} />
            <Route path="/products/adhesives/:variety" element={<AdhesivesComponent />} />
            <Route path="/stories/:variety" element={<NewComponent />} />
            <Route path="/products/WallPanels/:variety" element={<WallComponent />} />
            <Route path="/products/WallPanels/:variety/:variety2" element={<WallInside />} />
            <Route path="/products/WallPanels/:variety/:variety2/:variety3" element={<WallInside2 />} />
            <Route path="/products/ModularInteriors/:variety" element={<KitchensComponent />} />
            <Route path="/products/ModularInteriors/:variety/:variety2" element={<KitchenInside />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;