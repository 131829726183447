import loader from '../assets/images/loader/loader1.webm';

const LogoLoader = () => {
  return (
    <div className="fixed bg-black top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
      <div className="loader-logo">
        <video
          className="lg:h-[45vh] h-[70%] lg:w-[20vw] w-[70%] mx-auto"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={loader} type="video/webm" />
        </video>
      </div>
    </div>
  );
};

export default LogoLoader;