import React from 'react';
import Marquee from 'react-fast-marquee';

const MarqueeDiv = ({ images }) => {
  const shouldMarquee = images.length >= 4;

  return (
    <div className='w-screen  my-[1vw] '>
      {shouldMarquee ? (
        <Marquee speed={80}>
          {images.map((image, index) => (
            <div key={index} className='w-[25vw] mr-5 lg:mx-auto justify-center  items-center flex  lg:pb-8 lg:pt-2 lg:border-l border-black border-solid '>
              <img loading='lazy' className=' lg:object-fit  lg:h-[10vh] flex justify-center items-center ' src={require(`../../assets/images/${image}`)} alt='users images' />
            </div>
          ))}
        </Marquee>
      ) : (
        <div className='flex  max-md:mx-4justify-start    gap-3'>
          {images.map((image, index) => (
            <div
              key={index}
              className={`w-[25vw] justify-center items-center flex lg:pb-8 lg:pt-2   ${index !== images.length - 1 ? 'border-r  border-solid border-black' : ''
                }`}
            >
              <img
                loading='lazy'
                className={`object-fit h-[80px] lg:h-[15vh] mix-blend-multiply flex justify-center items-center `}
                src={require(`../../assets/images/${image}`)}
                alt='users images'
              />
            </div>
          ))}


        </div>
      )}
    </div>
  );
};

export default MarqueeDiv;
