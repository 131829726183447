import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../../Navbar/Navbar';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import WallInside2 from './WallInside2';
import WallComponentLink from './WallComponentLink';
import KitchenInside2 from './KitchenInside2';
import { Footer } from '../../Footer';
export default function KitchenInside() {
    const navigate = useNavigate();
    const { variety, variety2 } = useParams();
    const hasParentheses = variety2.includes('(') && variety2.includes(')');
    let decodedVariety; 
    let description;
    
    if (hasParentheses) {
        const parts = variety2.split(/(\([^)]+\))/);
        decodedVariety = parts[0].trim().replace(/_/g, '');
        decodedVariety = decodedVariety.charAt(0).toUpperCase() + decodedVariety.slice(1);
        description = parts[1].replace('(', '').replace(')', '').trim().replace(/_/g, ' ');
        description = description.charAt(0).toUpperCase() + description.slice(1);
    } else {
        decodedVariety = variety2.trim().replace(/_/g, '');
        decodedVariety = decodedVariety.charAt(0).toUpperCase() + decodedVariety.slice(1);
        description = '';
    }
    
    
    const [zoomIn, setZoomIn] = useState(false);
    useEffect(() => {
        setZoomIn(true);
    }, []);

    useEffect(() => {
        const handleRouteChange = () => {
            setZoomIn(false);
        };

        const unlisten = () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
        window.addEventListener('popstate', handleRouteChange);
        return unlisten;
    }, []);

    return (
        <div>
            <motion.div
            >
                <div className={`z-[100] `}>
                    <Header logo="logomenu.png" color="black" />
                </div>
                <div className={`new-component md:h-[80vh] h-[60vh] ${zoomIn ? 'zoom-in' : 'zoom-out'} relative`} style={{ position: 'relative' }}>
                    <h1 className="text-white text-center whitespace-nowrap absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica]  text-[6vw] md:text-[6vh]">
                        {decodedVariety} <br />
                        ({description})
                    </h1>
                    <img
                        className="overflow-hidden  object-fit md:h-[80vh] h-[60vh] w-screen"
                        src={require(`../../../assets/images/products/kitchen/KitchenTypes/${variety2}.jpg`)}
                        alt="selected story"
                    />
                    <div className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}>
                        <Link className={(variety2 === 'madeleine_(parallel_shape)' || variety2 === 'luciole_(l-shape)' || variety ==='wardrobes' ) ? 'invert' : 'invert-0'} onClick={() => navigate(-1)} >
                            <img src={require("../../../assets/images/project/arrow.png")} alt="" />
                        </Link>
                    </div>
                    <div className='flex absolute text-white bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1'>
                        <Link to="/">
                            Home
                        </Link>
                        /
                        <Link to="/products/ModularInteriors">
                            Products
                        </Link>
                        /
                        <p >
                        Modular Interiors
                        </p>
                        /
                        <p  >
                            {decodedVariety}
                        </p>
                    </div>
                </div>


                <div className="relative w-full   bg-[#F7F3EA] text-black ">

                    <div className='m-[3vw] pb-[3vw]' >
                        <div className=" flex items-center  justify-between  font-['Poppins'] my-10">
                            {variety2 === 'luciole_(l-shape)' && (
                                <div className="md:max-w-[80%] font-thin   md:text-[3vh] mt-4 font-['Poppins'] ">
                                    Bejewelled as the centre of attention, these L-shape kitchens have been designed to be cutting-edge with a traditional elan.                            </div>
                            )}
                            {variety2 === 'benissimo_(u-shape)' && (
                                <div className="md:max-w-[80%]  font-thin   md:text-[3vh] mt-4 font-['Poppins'] ">
                                    Make a style statement with these U-shape kitchens that leaves a lasting impression, owing to the intricate artistry and flawless craftsmanship.                            </div>
                            )}
                            {variety2 === 'stelle_(straight_shape)' && (
                                <div className="md:max-w-[80%]  font-thin  md:text-[3vh] mt-4 font-['Poppins'] ">
                                    Applauding the classic appeal and meticulous attention to detail, our straight kitchens boast brilliant finishes, resulting in a design that embodies elegance and style while being spectacularly crafted.                            </div>
                            )}
                            {variety2 === 'parallel_(madeleine)' && (
                                <div className="md:max-w-[80%]  font-thin  md:text-[3vh] mt-4 font-['Poppins'] ">
                                    Magnificence has a new destination with these superbly fashioned parallel kitchens that add a charisma of grandeur. Each parallel kitchen is a testament to exquisite design, evoking a sense of grandeur and refinement.                            </div>
                            )}
                            {variety2 === 'island_(essere)' && (
                                <div className="md:max-w-[80%]  font-thin  md:text-[3vh] mt-4 font-['Poppins'] ">
                                    Designed to redefine your cooking experience, our island kitchens seamlessly combine style, functionality, and versatility. Crafted with meticulous attention to detail and utilising top-quality materials, each island kitchen is a testament to luxury and practicality.                            </div>
                            )}
                            {variety2 === 'penishula_shape_(maestre)' && (
                                <div className="md:max-w-[80%]  font-thin  md:text-[3vh] mt-4 font-['Poppins'] ">
                                    Experience the perfect blend of state-of-the-art design and ergonomic comfort with our breathtaking peninsula kitchens.                            </div>
                            )}
                            {variety2 === 'allora_(walk-in)' && (
                                <div className="md:max-w-[80%]  font-thin  md:text-[3vh] mt-4 font-['Poppins'] ">
                                    Indulge in the epitome of luxury with our walk-in wardrobes, offering not just ample space, but also privacy, ensuring a personalised haven for all your wardrobe-related activities.                            </div>
                            )}
                            {variety2 === 'comodo_(sliding)' && (
                                <div className="md:max-w-[80%]  font-thin  md:text-[3vh] mt-4 font-['Poppins'] ">
                                    Sliding smoothly like silk, these wardrobes dance to your tunes. With expansive storage inside, it is the ideal space solution for your belongings.                            </div>
                            )}
                            {variety2 === 'monarce_(openable)' && (
                                <div className="md:max-w-[80%]  font-thin  md:text-[3vh] mt-4 font-['Poppins'] ">
                                    Openable wardrobes that are designed with an inbuilt poise. Elegant, durable and convenient, these are your perfect storage partners.                            </div>
                            )}
                        </div>
                        <div className='flex justify-center '>
                            <img className='md:h-screen'
                                src={require(`../../../assets/images/products/kitchen/KitchenTypes/${variety2}1.png`)}
                                alt="" />
                        </div>
                        <h1 className="[font-family:'PP_Hatton-medium',Helvetica] mb-[2vw] mt-[4vw]    text-[4vh] text-black">
                        Choose your Finish
                        </h1>
                        {variety === 'kitchens' && (
                        <div className='grid border-2 gap-[2vw] border-red-500 md:grid-cols-2 grid-cols-1 '>
                            <KitchenInside2 link="Finishes" imagestyle="h-[30vh]" motionType={"motion1"} variety={"Veneer"} path="Kitchen" />
                            <KitchenInside2 link="Finishes" imagestyle="h-[30vh]" motionType={"motion1"} variety={"Laminate"} path="Kitchen" />
                            <KitchenInside2 link="Finishes" imagestyle="h-[30vh]" motionType={"motion1"} variety={"Acrylic"} path="Kitchen" />
                            <KitchenInside2 link="Finishes" imagestyle="h-[30vh]" motionType={"motion1"} variety={"PU"} path="Kitchen" />
                        </div>
                        )}
                        {variety === 'wardrobes' && (
                        <div className='grid border-2 gap-[2vw] border-red-500 md:grid-cols-2 grid-cols-1 '>
                            <KitchenInside2 link="Finishes" imagestyle="h-[35vh]" motionType={"motion1"} variety={"Veneer "} path="Wardrobes" />
                            <KitchenInside2 link="Finishes" imagestyle="h-[35vh]" motionType={"motion1"} variety={"Laminate "} path="Wardrobes" />
                            <KitchenInside2 link="Finishes" imagestyle="h-[35vh]" motionType={"motion1"} variety={"Glass"} path="Wardrobes" />
                            <KitchenInside2 link="Finishes" imagestyle="h-[35vh]" motionType={"motion1"} variety={"PU"} path="Wardrobes" />
                        </div>
                        )}
                    </div>
                </div>
                <Footer />
            </motion.div>
        </div>
    );
}
