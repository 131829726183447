import React, { useState, useEffect } from "react";
import Header from "../components/Navbar/Navbar";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ToggleSwitch from "../components/ToggleSwitch";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from 'react-helmet';

const Dropdown = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sm:m-2 max-md:mt-4 transition-all duration-600 my-2 space-y-2">
      <div
        className="group flex flex-col  transition-all duration-600 rounded-lg py-[.5vw] text-black"
        tabIndex="1"
      >
        <div className="flex cursor-pointer transition-all duration-600 w-full justify-between items-center">
          <div
            onClick={toggleDropdown}
            className="flex  justify-center items-center"
          >
            <MdOutlineKeyboardArrowDown
              className={`transition-all duration-600 text-xl sm:text-[1.2vw] filter duration-500 ${isOpen ? "-rotate-180" : "-rotate-90"
                }`}
            />{" "}
            <h className="font-medium transition-all duration-600 text-xl sm:text-[1.2vw] ">
              {" "}
              {title}
            </h>
          </div>
          {title === "Necessary" && (
            <h1 className="font-medium">Always Active</h1>
          )}
          <h1 className={`${title === "Necessary" ? "hidden" : ""}`}>
            <ToggleSwitch />
          </h1>
        </div>
        <div
          className={` transition-all duration-600 ${isOpen
            ? "visible h-auto text-[15px]   max-w-[90%] opacity-100 transition-all duration-200 sm:duration-1000"
            : "invisible h-0  duration-600  max-h-0 opacity-0 transition-all"
            } items-center font-normal`}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  const images = [
    require("../assets/images/home/Property1=Group148.webp"),
    require("../assets/images/home/Property1=Group145.webp"),
    require("../assets/images/home/Property1=Group144.webp"),
    require("../assets/images/home/Property1=Group147.webp"),
    require("../assets/images/home/Property1=Group146.webp"),
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [showManageCookies, setShowManageCookies] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    setCookiesAccepted(true);
    if (cookiesAccepted === "true" || cookiesAccepted === "false") {
      setShowCookieConsent(false);
    }
  }, []);
  

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
    setShowCookieConsent(false);
    localStorage.setItem("cookiesAccepted", "true");
  };

  const handleDeclineCookies = () => {
    setCookiesAccepted(true);
    setShowCookieConsent(false);
    localStorage.setItem("cookiesAccepted", "false");
  };

  const toggleManageCookies = () => {
    setShowManageCookies(!showManageCookies);
    handleAcceptCookies();
  };

  const toggleManageCookies1 = () => {
    setShowManageCookies(false);
    setShowCookieConsent(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2500);

    return () => clearInterval(interval);
  }, [images.length]);

  return (

    <>
          <Helmet>
        <title>Home - Vikas Plywood - One Stop Solution</title>
        <meta name="description" content="Your go-to for interior and exterior solutions in Hyderabad. Plywood, veneers, laminates, wooden flooring, AGT wall panels, modular furniture & more." />
        <meta property="og:keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak " />
        <meta name="keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak ,Vikas plywood, Best plywood store, Plywood store near me, Veneer store near me, VIN grip, Laminates, Wall panels, AGT wall panels, Solid wood profiles, Wooden flooring, Laminate flooring, IPE decking, IPE flooring, Exterior cladding, WPC cladding, WPC decking, Adhesives " />
        <meta property="og:url" content="https://www.vikasplywood.com" />
        
      </Helmet>

      {showCookieConsent && (
        <div className="fixed z-20 bottom-0 left-0 right-0 backdrop-blur-[10px] bg-[#dfe0cc9c] p-4">
          <p className="md:text-[1vw] max-md:font-medium pt-[1vw] text-black">
            We use cookies to enhance your experience on the Vikas Plywood
            website. Some cookies are essential for the site's
            functionality, while others are optional and help us personalize
            your experience, including advertising and analytics. You can
            choose to accept all cookies, decline optional cookies, or
            manage your preferences. If you don't make a selection, our
            default cookie settings will apply. You can update your
            preferences at any time.
          </p>
          <div className="flex items-center justify-center md:justify-end mt-5 md:mt-[2vw]">
            <button
              className="md:text-[1vw] max-md:hidden underline mr-[2vw]"
              onClick={toggleManageCookies}
            >
              Manage Cookies
            </button>
            <button
              onClick={handleAcceptCookies}
              className="bg-black sm:text-[1vw]  border-black border-[2px] hover:bg-white hover:text-black transition-all  duration-100 text-white whitespace-nowrap text-[5vw] px-2 md:px-[1vw] py-[0.8vw] mr-2"
            >
              Accept All Cookies
            </button>
            <button
              onClick={handleDeclineCookies}
              className="border-black border-[2px] sm:text-[1vw] hover:bg-white hover:text-black transition-all duration-100 text-black whitespace-nowrap text-[5vw] px-2 md:px-[1vw] py-[0.8vw]"
            >
              Decline Cookies
            </button>
          </div>
          <button
            className="md:text-[1vw] md:hidden flex w-full my-3 justify-end underline mr-[2vw]"
            onClick={toggleManageCookies}
          >
            Manage Cookies
          </button>
        </div>
      )}

      {showManageCookies && (
        <div className="fixed z-20 [font-family:'Poppins',Helvetica]text-[1vw] font bottom-0 left-0 right-0 backdrop-blur-[10px] bg-[#dfe0cc9c] p-4">
          <div className=" p-4 text-sm sm:text-[1vw] ">
            <h2 className=" mb-4 text-xl sm:text-[1.2vw] font-bold">
              Manage Cookies
            </h2>
            <span className="max-md:font-medium">
              {" "}
              This site uses cookies. We use cookies mainly to improve and
              analyze your experience on our websites and for marketing
              purposes. Because we respect your right to privacy, you can
              choose not to allow some types of cookies. Click on the
              different category headings to find out more and change your
              default settings. Blocking some types of cookies may
              negatively impact your experience on the site and limit the
              services we are able to provide.
            </span>
            <Dropdown
              title="Necessary"
              content="These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms."
            />
            <Dropdown
              title="Analytics"
              content="These cookies help us to understand how visitors engage with the website. We may use a set of cookies to collect information and report site usage statistics. In addition to reporting site usage statistics, data collected may also be used, together with some of the advertising cookies described, to help show more relevant ads across the web and to measure interactions with the ads we show."
            />
            <Dropdown
              title="Advertisement"
              content="We use cookies to make our ads more engaging and valuable to site visitors. Some common applications of cookies are to select advertising based on what's relevant to a user; to improve reporting on ad campaign performance; and to avoid showing ads the user has already seen."
            />
            <div className="flex sm:flex-row flex-col justify-between w-full">
              <div className="flex items-center md:hidden justify-end mt-[2vw]">
                <button
                  onClick={toggleManageCookies}
                  className="bg-black max-md:my-3  sm:text-[1vw] border-black border-[2px] hover:bg-white hover:text-black transition-all duration-100 text-white max-md:p-2 text-[4vw] md:px-[1vw] py-[0.8vw] mr-2"
                >
                  Save Changes
                </button>
              </div>
              <div className="flex items-center justify-center sm:justify-start mt-[2vw]">
                <button
                  onClick={toggleManageCookies}
                  className="bg-black sm:text-[1vw] border-black border-[2px] hover:bg-white hover:text-black transition-all duration-100 text-white max-md:p-2 text-[4vw] md:px-[1vw] py-[0.8vw] mr-2"
                >
                  Accept All Cookies
                </button>
                <button
                  onClick={toggleManageCookies}
                  className="border-black border-[2px] sm:text-[1vw] hover:bg-white hover:text-black transition-all duration-100 text-black max-md:p-2 text-[4vw] md:px-[1vw] py-[0.8vw]"
                >
                  Decline Cookies
                </button>
              </div>
              <div className="flex max-md:hidden items-center  mt-[2vw]">
                <button
                  onClick={toggleManageCookies}
                  className="bg-black text-[1vw] border-black border-[2px] hover:bg-white hover:text-black transition-all duration-100 text-white px-[1vw] py-[0.8vw] mr-2"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="relative">
        <div className="overlay" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: cookiesAccepted ? -1 : 1000 }}></div>
        <Header logo="logo.png" color="white" />
        <AnimatePresence>
          <motion.img
            key={currentImageIndex}
            src={images[currentImageIndex]}
            alt="Wooden Textures"
            className="fixed -z-10 h-screen w-screen object-cover"
            initial={{ opacity: 0.2 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          />
        </AnimatePresence>
        <div className={`relative transition-all duration-100 h-screen w-screen flex justify-center items-center`}>
          <div className=" text-white text-center font-thin [font-family:'Big-Caslon']">
            <h1 className="md:text-[5vw] text-[40px] md:leading-[6vw]">
              V I K A S
            </h1>
            <h1 className="md:text-[2vw] text-[20px] leading-none">
              PLYWOOD
            </h1>
          </div>
        </div>
      </div>
    </>

  );
};

export default HomePage;
