import React, { useState } from "react";
import FormPopup from "../../download/FormPopup";

const PdfEnquiry = ({ style, link, category, name,text,imageHeight, variety }) => {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleEnquiry = () => {
    const phoneNumber = "+917093573311";
    const message = `I am interested in \n*${name}* of *${variety}*.\nPlease provide more information.`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <div
      style={{ border: style }}
      className="md:m-[2vh] m-2 max-md:max-w-[400px] max-sm:w-[90vw]  max-md:mx-auto border-[1px] border-solid border-black"
    >
      <div className="p-[1vw]  max-md:m-2 flex sm:flex-row flex-col border-s border-black">
        <div>
          <img
             className={`lg:w-[30vw] ${imageHeight}  border-black max-sm:border-solid border-[0.5px] max-sm:my-2 mx-auto max-sm:max-w-[275px]  max-sm:h-[400px] h-[300px] lg:h-[60vh]`}
             src={require(`../../assets/images/products/${category}/${name}.png`)}
           />
          
        </div>
        <div className="flex  px-[1vw] mt-[1vw] flex-col justify-between">
          <div>
            <h1 className="[font-family:'PP_Hatton-medium',Helvetica] lg:mb-[1vw] max-sm:pl-6 text-[3.5vh] sm:text-[4.5vh]">
              {name}{" "}
            </h1>
          </div>
          <h1 className="">{text}</h1>

          <div>
            <button
              onClick={handleEnquiry}
              className="bg-[#6d744c] font-serif md:text-[1.5vw] my-[1vw] p-2 w-full text-white"
            >
              Enquire Now
            </button>
            <button
              onClick={openPopup}
              className="border-[#6d744c] font-serif md:text-[1.5vw] border-[1px] border-solid p-2 w-full text-[#6d744c]"
            >
              Download Now
            </button>
          </div>
          {showPopup && <FormPopup onClose={closePopup} link={link} />}
        </div>
      </div>
    </div>
  );
};

export default PdfEnquiry;
