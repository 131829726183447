import React from 'react'
import { motion } from 'framer-motion';
import { planetVariants, staggerContainer, fadeIn, slideIn } from '../../utils/motion'
const Founder = () => {

    return (
        <div className='max-md:mx-2 bg-[#F7F3EA]  px-[4vw]'>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }} className='flex lg:flex-row flex-col-reverse justify-between ] '>
                <motion.div
                    variants={fadeIn('up', 'tween', 0.1, 1)}
                    className=" flex flex-col top-[2.5vw] relative  lg:w-[25vw] justify-center lg:h-screen">
                    <img src={require("../../assets/images/about/founder.png")} alt="Niranjan Lal on the chair of power" />
                </motion.div>
                <motion.div
                    variants={fadeIn('up', 'tween', 0.1, 1)}
                    className='lg:h-screen max-lg:my-4 relative inset-0 top-[1vw] flex flex-col items-start justify-center'>
                    <h className="flex mb-[1vw] [font-family:'PP_Hatton-medium',Helvetica]  md:items-center lg:text-[3vw] text-[25px]  font-medium lg:leading-[2.4vw] text-[#000]   z-[4]">
                        Our Founder
                    </h>
                    <h className="flex lg:w-[64vw]  font-thin font-['Poppins'] md:justify-start md:items-center  text-[20px] leading-6 lg:leading-[2vw] lg:text-[3vh] text-[#000]   z-[4]">
                        Hailing from a small town in Rajasthan, Niranjan Lal and his 4 brothers ventured out into the Indian subcontinent looking for business opportunities. Together with family, and the true spirit of a Marwari merchant, they found themselves in the vibrant city of Hyderabad.<br /><br />

                        Niranjan Lal Agarwal planted the seeds of Vikas Plywood in 1975 and grew this company over many decades with a strong sense of leadership and perseverance.

                    </h>
                </motion.div>
            </motion.div>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className='flex  lg:flex-row flex-col justify-between gap-6 '>
                <motion.div
                    variants={fadeIn('up', 'tween', 0.2, 1)}
                    className='relative inset-0  max-lg:mt-[60px] lg:w-[45vw] flex flex-col items-start justify-end'>
                    <h className="flex lg:mb-[1vw] [font-family:'PP_Hatton-medium',Helvetica]  md:items-center lg:text-[3vw] text-[25px]  font-medium lg:leading-[2.4vw] text-[#000]   z-[4]">
                        <span className=''>A Family Legacy</span>
                    </h>
                    <h className="flex flex-col   font-thin font-['Poppins'] md:justify-start md:items-center lg:leading-[2vw] lg:text-[3vh] text-[20px] leading-6  text-[#000]   z-[4]">
                        Among the keys to Vikas Plywood’s success is undeniably the honesty and love that the Goel family carries on from generation to generation. <br /><br />
                        Vikas Plywood is a family business guided by a coherent entrepreneurial vision, and the founding values of respect and familial ethics that constitute our working method. <br /><br />
                        The generational legacy is not limited to the Goel family, but also extends to some of our oldest employees whose next of kin have become a part of our company today. It’s a testament to our core belief: When we grow, we help our people grow too.

                    </h>
                </motion.div>
                <motion.div
                    variants={fadeIn('up', 'tween', 0.2, 1)} className=" flex  flex-col  justify-center ">
                    <img className='md:h-screen mx-auto' src={require("../../assets/images/about/founder2.png")} alt="Three generations of the Goel family together" />
                </motion.div>
            </motion.div>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }} className='flex flex-col mt-[4vw] justify-center lg:h-screen mb-[40px] lg:my-[80px]  '>
                <motion.div
                    variants={fadeIn('up', 'tween', 0.2, 1)} className=' relative inset-0  flex flex-col justify-center'>
                    <h className="flex  lg:justify-center mx-auto [font-family:'PP_Hatton-medium',Helvetica] lg:text-center items-center md:text-[3vw] lg:text-[3vw] text-[25px]    my-4 font-thin  text-[#000]   z-[4]">
                        The Powerhouse
                    </h>

                </motion.div>
                <motion.div
                    variants={fadeIn('up', 'tween', 0.2, 1)} className=" flex flex-col lg:w-[90vw] w-full mx-auto  relative  justify-center ">
                    <img src={require("../../assets/images/about/founder3.jpeg")} cla alt="The powerhouse referring to the entire team of Vikas Plywood" />
                </motion.div>

            </motion.div>
        </div>
    )
}

export default Founder