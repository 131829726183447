import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BurgerAndMenu from "../burgerAndMenu/BurgerAndMenu";

const Header = ({ logo, color }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = currentScrollPos > prevScrollPos;

      setPrevScrollPos(currentScrollPos);

      setIsScrolled(isScrollingDown && currentScrollPos > window.innerHeight * 0.05);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-30 flex items-center justify-between px-4 py-2 bg-transparent transition-all duration-300 ease-in-out ${
        isScrolled ? "-translate-y-full opacity-0" : "translate-y-0 opacity-100"
      }`}
    >
      <a href="/">
        <div className="flex items-center">
          <div className="mr-4">
            <img
              src={require(`../../assets/images/${logo}`)}
              alt="Logo"
              className="md:w-[7vw] w-[80px] filter brightness-[200%]"
            />
          </div>
        </div>
      </a>
      <div className="flex">
        <BurgerAndMenu color={color} />
      </div>
    </header>
  );
};

export default Header;
