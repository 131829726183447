import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <div className="bg-[#F7F3EA] relative z-20 overflow-hidden">

            <div className=" max-md:min-h-screen flex flex-row justify-center mx-[3vw] mt-[4vw] relative   ">
                <div className=" relative z-50 max-md:flex max-md:flex-col max-md:justify-between  w-screen ">
                    <div className="relative flex flex-col md:flex-row justify-between ">
                        <div className="relative max-md:mt-[10px] [font-family:'PP_Hatton-thin',Helvetica] font-thin  text-first md:text-[4vw] text-[50px] tracking-[0] leading-[50px] md:leading-[4vw]">
                            LET’S <br /> GROW
                            <br />
                            TOGETHER
                            <div className="grid max-lg:w-[80%] text-[20px] grid-cols-3 lg:text-[1.3vw] mt-[1vw]  justify-center ">
                                <a href="https://www.facebook.com/vikasplywoods" target="_blank" rel="noopener noreferrer">
                                    <i className="fab  fa-facebook fa-2x"></i>
                                </a>
                                <a href="https://www.instagram.com/vikasplywood" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram fa-2x"></i>
                                </a>

                                <a href="https://in.linkedin.com/company/vikas-plywood-and-veneers" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin fa-2x"></i>
                                </a>
                            </div>

                        </div>
                        <div className="grid  md:grid-cols-3  ">
                            <p className="relative z-10  max-md:hidden  [font-family:'Poppins',Helvetica] font-thin text-first text-[1.4vw] ">
                                <span className="">
                                    <Link to='/' className="hover:font-bold">Home</Link>
                                    <br />
                                    <Link to='/about' className="hover:font-bold">About</Link>
                                    <br />
                                    <Link to='/vinboutique' className="hover:font-bold">Vin Boutique </Link>
                                </span>
                                <br />
                                <span className="font-[number:var(--secondary-font-weight)] font-secondary [font-style:var(--secondary-font-style)] tracking-[var(--secondary-letter-spacing)] leading-[var(--secondary-line-height)] text-[length:var(--secondary-font-size)]">
                                    <Link to='/products/Plywood' className="hover:font-bold">Products</Link>
                                </span>
                            </p>
                            <p className="relative max-md:hidden z-10 [font-family:'Poppins',Helvetica] font-thin text-first text-[1.4vw] ">
                                <span className="">
                                    <Link to='/projects' className="hover:font-bold">Projects</Link>
                                    <br />
                                    <Link to='/stories' className="hover:font-bold">Stories</Link>
                                    <br />
                                    <Link to='/Catalogues' className="hover:font-bold">Catalogues</Link>
                                </span>
                                <br />
                                <span className="font-[number:var(--secondary-font-weight)] font-secondary [font-style:var(--secondary-font-style)] tracking-[var(--secondary-letter-spacing)] leading-[var(--secondary-line-height)] text-[length:var(--secondary-font-size)]">
                                    <Link to='/contact' className="hover:font-bold">Contact</Link>
                                </span>
                            </p>

                            <p className="relative z-10 max-md:hidden  [font-family:'Poppins',Helvetica] font-thin text-first md:text-[1.4vw] text-[20px]">
                                <span className="">
                                    <span className="md:text-[1.4vw] text-[20px] [font-family:'PP_Hatton-medium',Helvetica] relative mb-10">
                                        Contact Information
                                    </span>
                                    <br />
                                    +91 7093573311
                                    <br />
                                    +91 9177222405
                                    <br />
                                    <a target='_blank' href="mailto:marketing@vikasplywood.com ">marketing@vikasplywood.com</a>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="relative ">
                        <p className="[font-family:'PP_Hatton-Medium',Helvetica] md:text-[1.2vw] text-[20px]  "> <span >Visit our showroom</span>  </p>
                        <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/jAWPdg3j27df2hsi6" className="relative   z-[1] text-[4.6vw]  md:text-[1.1vw] [font-family:'Poppins',Helvetica]   font-thin  ">
                            Plot No. 8-2-293/82/A/844, Road No.44,  <br /> Opposite Electric Substation, Jubilee Hills, <br /> Hyderabad - 500033
                        </a>
                    </div>
                    <div className="flex w-[95%]  mx-auto justify-between">
                        <p className="relative z-10  md:hidden text-[4.6vw]   [font-family:'Poppins',Helvetica]  font-thin text-first md:text-[1.4vw] ">
                            <span className="">
                                <Link to='/' className="hover:font-bold">Home</Link>
                                <br />
                                <Link to='/about' className="hover:font-bold">About</Link>
                                <br />
                                <Link to='/vinboutique' className="hover:font-bold">Vin Boutique </Link>
                            </span>
                            <br />
                            <span className="font-[number:var(--secondary-font-weight)] font-secondary [font-style:var(--secondary-font-style)] tracking-[var(--secondary-letter-spacing)] leading-[var(--secondary-line-height)] text-[length:var(--secondary-font-size)]">
                                <Link to='/products/Plywood' className="hover:font-bold">Products</Link>
                            </span>
                        </p>
                        <p className="relative md:hidden text-[4.6vw]  z-10 [font-family:'Poppins',Helvetica] font-thin text-first md:text-[1.4vw] ">
                            <span className="">
                                <Link to='/projects' className="hover:font-bold">Projects</Link>
                                <br />
                                <Link to='/stories' className="hover:font-bold">Stories</Link>
                                <br />
                                <Link to='/Catalogues' className="hover:font-bold">Catalogues</Link>
                            </span>
                            <br />
                            <span className="font-[number:var(--secondary-font-weight)] font-secondary [font-style:var(--secondary-font-style)] tracking-[var(--secondary-letter-spacing)] leading-[var(--secondary-line-height)] text-[length:var(--secondary-font-size)]">
                                <Link to='/contact' className="hover:font-bold">Contact</Link>
                            </span>
                        </p>
                    </div>
                    <p className="relative md:hidden  [font-family:'Poppins',Helvetica] font-thin text-first md:text-[1.4vw] text-[4.6vw]">
                        <span className="">
                            <span className="md:text-[1.4vw]  [font-family:'PP_Hatton-medium',Helvetica] relative mb-10">
                                Contact Information
                            </span>
                            <br />
                            +91 7093573311
                            <br />
                            +91 9177222405
                            <br />
                            marketing@vikasplywood.com
                        </span>
                    </p>
                    <p className="font-bold  [font-family:'Poppins-Light',Helvetica] mb-[2vw] mt-[1vw] underline md:text-[1vw] relative z-[1] "> <Link to={"/privacypolicy"}>Privacy Policy</Link></p>
                    <div className="relative  md:leading-[9vw]  [font-family:'PP_Hatton-Medium',Helvetica] font-medium text-first text-[9.8vw] md:text-[10vw]  ">
                        VIKAS <br /> PLYWOOD
                    </div>
                    <img
                        className=" absolute max-md:w-screen -z-0  md:h-[100vh]  right-0 bottom-0 "
                        alt="Maskgroup"
                        src={require('../assets/images/contact/gradient2.png')}
                    />

                </div>
            </div>
<h1 className="absolute font-['Poppins'] text-[2.5vw] lg:text-right md:text-[0.8vw] mx-[3vw]  font-thin  z-10 right-0 max-md:mb-[2vw] bottom-[2vw]">
                {/* Created by softwarelance <br />| */}
                 © 2024 by vikasplywood</h1>
        </div>
    );
};
