import React, { lazy, Suspense, useEffect, useState } from 'react';
import { motion } from "framer-motion";
import OurTeam from './OurTeam';
import MainHero from './MainHero';

const isMobile = () => window.innerWidth <= 768;

const AboutSection = lazy(() => import('./AboutSections'));
const Founder = lazy(() => import('./Founder'));

export default function AboutUs() {
  const [isMobileView, setIsMobileView] = useState(isMobile());

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(isMobile());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <motion.div>
      <div>
        <img className='h-[70vh] md:hidden w-screen' src="./aboutmobile.webp" alt="A birch plywood wall describing the brand story of Vikas Plywood" />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {isMobileView && <AboutSection />}
      </Suspense>
      {!isMobileView && (
        <div className='max-md:hidden'>
          <MainHero />
        </div>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        {isMobileView && <Founder />}
      </Suspense>
      <OurTeam />
    </motion.div>
  );
}
