import React, { useState, useEffect } from 'react';
import ProjectLink from './ProjectLink';
import { Footer } from '../Footer';
import { Link } from 'react-router-dom';
import MarqueeDiv from '../products/MarqueeDiv';

const images = [
  {src: require("../../assets/images/project/banner.png"), alt: "Background picture 1"},
  {src: require("../../assets/images/project/AGT_AH Flour Mill.jpg"), alt: "AGT wall profiles installed on a double-height wall"}
];


const marqueeimages = [
  "project/marquee/Group 220.png",
  "project/marquee/Rectangle 201.png",
  "project/marquee/Rectangle sgfkjdng.png",
  "project/marquee/Rectangle 198.png",
  "project/marquee/Rectangle 194.png",
  "project/marquee/dscd.png",
  "project/marquee/Rectangle 195.png",
  "project/marquee/Rectangle 196.png",
  "project/marquee/Rectangle 202.png",
  "project/marquee/Rectangle 204.png",
  "project/marquee/cdscscxvs.png",
  "project/marquee/HDFC_Bank_Logo2.png",
  "project/marquee/Rectangle 200.png",
  "project/marquee/Rectangle 197.png",
];

export default function ProjectHome() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='overflow-hidden '>
      <div >
        <img
          className='md:h-[75vh] object-cover h-[80vh] w-screen transition-opacity duration-1000'
          src={images[currentImageIndex].src}
          alt={images[currentImageIndex].alt}
        />
      </div>
      <div className=' mx-[2vw]  bg-[#f7f3ea] relative overflow-hidden my-0'>
        <h1 className="flex   my-[2vw]  justify-start items-start [font-family:'PP_Hatton-medium',Helvetica] text-[6vw] max-md:mt-4 sm:text-[3vw] font-medium md:leading-[56.7px] text-[#000] relative text-left overflow-hidden z-[25] ">
          Projects by Vikas
        </h1>
        <div className='my-[2vw]'>
      <h className="md:max-w-[90%]    md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
          Discover our esteemed projects across diverse industries, catalyzing growth at every frontier.
        </h>
      </div>
      </div>
      <h1 className="flex  mx-[2vw] justify-start items-start [font-family:'PP_Hatton-medium',Helvetica] text-[4vw]  sm:text-[2vw] font-medium  text-[#000] relative text-left overflow-hidden z-[25] ">
          Trusted by
        </h1>
      <div className={`flex justify-center mb-[2vw]  transition-opacity duration-500 `}>
        <MarqueeDiv images={marqueeimages} />
      </div>
      
      <div className="grid  mx-[2vw] mt-[2vw] gap-[2vw] grid-cols-1 md:grid-cols-2">
        <ProjectLink alt="AGT 3771 German Oak used on a double height wall" variety="MyScape Villas" path="project/image1.png" />
        <ProjectLink alt="Pre-polished VIN Veneers used in living room" variety="SHIV House" path="project/image1.png" />
        <ProjectLink alt="Satwalekar’s Dental Clinic entry space decorated with AGT wall profiles"  variety="Satwalekar’s Dental Clinic" path="project/image1.png" />
        <ProjectLink alt="Cara Cara Hyderabad"  variety="Cara Cara" path="project/image1.png" />
        <ProjectLink alt="AGT 114 Teak shade installed in Film nagar Hyderabad"  variety="Film Nagar Residence" path="project/image1.png" />

      </div>
      <Footer />
    </div>
  );
}
