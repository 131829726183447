import { useState,useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../Navbar/Navbar';
import { useParams, useLocation, Link } from 'react-router-dom';

export default function ImageComponent2() {
    const location = useLocation();
    const {  variety } = useParams();
    // const decodedVariety = variety.replace(/_/g, ' ').toUpperCase();
    const decodedVariety = variety .split("_")  .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    const path  = location.state.path; 
    console.log(path)
    const [zoomIn, setZoomIn] = useState(false);
    useEffect(() => {
        setZoomIn(true);
    }, []);
    useEffect(() => {
        const handleRouteChange = () => {
            setZoomIn(false);
        };

        const unlisten = () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
        window.addEventListener('popstate', handleRouteChange);

        return unlisten;
    }, []);


    return (
        <div>
            <motion.div
            >
               <div className={`z-[100] `}>
                    <Header logo="logomenu.png" color="black" />
                </div>  
                <div className={`new-component h-[80vh] ${zoomIn ? 'zoom-in' : 'zoom-out'} relative`} style={{ position: 'relative' }}>
                    <h1 className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vh]">
                        {decodedVariety}
                    </h1>
                    <img
                        className="overflow-hidden h-[80vh] w-screen"
                        src={require(`../../assets/images/${path}`)}
                        alt="selected story"
                    />
                    <div className={`absolute bottom-5 z-40 m-10 left-0`}>
                        <Link to={`/vinboutique`} >
                            <img src={require("../../assets/images/project/arrow.png")} alt="" />
                        </Link>
                    </div>
                </div>
                    <div className="relative  w-full  bg-[#F7F3EA] text-black p-4">
                        <div className="m-8 my-[70px]">
                            <h1 className="flex flex-col   justify-start items-start [font-family:'PP_Hatton-medium',Helvetica] text-[45px] font-medium leading-[56.7px] relative text-left overflow-hidden z-[25] ">Wall Panels
                            </h1>
                        </div>
                        <div className="max-w-[700px] font-['Poppins'] my-10 mx-[50px]">
                            Operating as a family business for over 40 years, Vikas plywood group prides itself on its unmatched commitment to customer service. Our primary purpose is establishing a connection with all our clients and caring for their needs as if they were our own. We believe that our personalized shopping experience and the feeling of family is what sets us apart from the rest of the market.
                        </div>
                    </div>
            </motion.div>
        </div>
    );
}
