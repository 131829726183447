import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../../Navbar/Navbar";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import WallInside2 from "./WallInside2";
import WallinsideLink from "./WallinsideLink";
import { Footer } from "../../Footer";
export default function WallInside() {
  const navigate = useNavigate();
  const { image, variety2 } = useParams();
  const decodedVariety = variety2.replace(/_/g, " ").toUpperCase();
  const [zoomIn, setZoomIn] = useState(false);
  useEffect(() => {
    setZoomIn(true);
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setZoomIn(false);
    };

    const unlisten = () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
    window.addEventListener("popstate", handleRouteChange);
    return unlisten;
  }, []);

  return (
    <div>
      <motion.div>
        <div className={`z-[100] `}>
          <Header logo="logomenu.png" color="black" />
        </div>
        <div
          className={`new-component h-[60vh]  ${zoomIn ? "zoom-in" : "zoom-out"
            } relative`}
          style={{ position: "relative" }}
        >
          <h1 className="text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vh]">
            {decodedVariety}
          </h1>
          <div className="flex bg-white">
            <img
              className="overflow-hidden h-[60vh] w-screen"
              src={require(`../../../assets/images/products/wall/agt/${variety2}n.png`)}
              alt="selected story"
            />
          </div>

          <div
            className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}
          >
            <Link to={`/products/WallPanels/agt_wall_panels/`}>
              <img
                src={require("../../../assets/images/project/arrow.png")}
                alt=""
                style={{ cursor: "pointer" }}
              />
            </Link>
          </div>
          <div className="flex absolute text-black bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1">
            <Link to="/">Home</Link>/
            <Link to="/products/WallPanels">Products</Link>/<p>WallPanels</p>/
            <p>{decodedVariety.split(" ")[0]}</p>
          </div>
        </div>
        <div className="relative w-full   bg-[#F7F3EA] text-black ">
          <div className="m-[3vw] pb-[3vw]">
            {variety2 === "3771" && (
              <div className="grid border-2 gap-[2vw] border-red-500 md:grid-cols-4 grid-cols-1 ">
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"231 Plain White"}
                  path="1"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"239 Light Walnut"}
                  path="2"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"248 Teak"}
                  path="3"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"251 Tobacco"}
                  path="4"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"369 German Oak"}
                  path="5"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"376 New Oak"}
                  path="6"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"723 Soft Touch Black"}
                  path="7"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"3011 London Blues"}
                  path="8"
                />
              </div>
            )}
            {variety2 === "3783" && (
              <div className="grid border-2 gap-[2vw] border-red-500 md:grid-cols-4 grid-cols-1 ">
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"231 Plain White"}
                  path="1"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"239 Light Walnut"}
                  path="2"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"251 Tobacco"}
                  path="4"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"726 Storm Grey"}
                  path="4"
                />

                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"728 Soft Touch Stone Grey"}
                  path="5"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"729 Soft Touch New Grey"}
                  path="6"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"735 Soft Touch Relax Green"}
                  path="7"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"3011 London Blues"}
                  path="8"
                />
              </div>
            )}
            {variety2 === "2250" && (
              <div className="grid border-2 gap-[2vw] border-red-500 md:grid-cols-4 grid-cols-1 ">
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"231 Plain White"}
                  path="1"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"237 Maple White"}
                  path="1"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"239 Light Walnut"}
                  path="2"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"248 Teak"}
                  path="4"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"251 Tobacco"}
                  path="4"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"375 Arizona Oak"}
                  path="4"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"726 Soft Touch Storm Grey"}
                  path="5"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"735 Soft Touch Relax Green"}
                  path="7"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"3011 London Blues"}
                  path="8"
                />
              </div>
            )}
            {variety2 === "2050" && (
              <div className="grid border-2 gap-[2vw] border-red-500 md:grid-cols-4 grid-cols-1 ">
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"231 Plain White"}
                  path="1"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"248 Teak"}
                  path="4"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"251 Tobacco"}
                  path="4"
                />
                <WallinsideLink
                  link={`wall/agt/${variety2}`}
                  variety={"369 German Oak"}
                  path="4"
                />
              </div>
            )}
          </div>
        </div>
        <Footer />
      </motion.div>
    </div>
  );
}
