import React, { useState } from "react";
import Header from "../components/Navbar/Navbar";
import { Footer } from "../components/Footer";
import FormPopup from "../download/FormPopup";
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';


const ProductCard = ({ productName, pdfLink, image }) => {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };
  const [isHovered, setIsHovered] = useState(false);
  const handleHoverStart = () => {
    setIsHovered(true);
  };

  const handleHoverEnd = () => {
    setIsHovered(false);
  };
  const closePopup = () => {
    setShowPopup(false);
  };
  const handleSubmit = () => {
    window.location.href = pdfLink;
  };
  return (
    <div
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
      className="mb-[2vw] cursor-pointer relative "
    >
      <div className=" relative overflow-hidden w-full" onClick={openPopup}>
        <img
          src={image}
          className="]"
          alt=""
        />

        <motion.div
          className={`absolute bottom-0 overflow-hidden left-0 w-full h-full flex justify-start items-end ${isHovered ? 'block' : 'hidden'}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: isHovered ? 1 : 0, scale: isHovered ? 1 : 0.8 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.295)' }}
        >
          <div className="p-4 text-white flex text-start">
            <h1 className="[font-family:'PP_Hatton-medium',Helvetica] lg:text-[4.5vh]">
              Click to Download 
            </h1>
          </div>
        </motion.div>


      </div>
      <h1 className="lg:text-[1.5vw] text-[3.6vw] font-['Poppins'] relative  ">
        {productName}
      </h1>
      {showPopup && <FormPopup link={pdfLink} onClose={closePopup} onSubmit={handleSubmit} />}
    </div>


  );
};
const ProductList = ({ products }) => {
  return (
    <div className="grid self-center place-items-center gap-[2vw] grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {products.map((product, index) => (
        <ProductCard key={index} productName={product.name} image={product.image} pdfLink={product.pdfLink} />
      ))}
    </div>
  );
};
const veneers = [
  { name: 'Greenlam Decowood', image:require("../assets/images/catalogues/Veneers/Decowood_Vikas Plywood.png"), pdfLink: '1gruQCAjZEKlrP0FqRhrUX1pQzjkCgx4e' },
  { name: 'Greenply Woodcrest',image:require("../assets/images/catalogues/Veneers/Greenply Woodcrest_Vikas Plywood.png"),  pdfLink: '1dUQBDrSlbq9Xli3BLHBggkvAuXMAMsbO' },
  { name: 'Hunsur Veneers', image:require("../assets/images/catalogues/Veneers/Hunsur Veneers-Vikas Plywood.png"),  pdfLink: '1ks3MwEtOe25vGZ__b-DhOjdfNVXotDde' },
  { name: 'VIN Natural Veneers',image:require("../assets/images/catalogues/VIN/VP website thumbnails-16.png"),  pdfLink: '1pqXjBstIoxPv1UFMxaDy63HShJWBn40R' },
  { name: 'VIN Pre-polished Veneers',image:require("../assets/images/catalogues/VIN/VP website thumbnails-17.png"),  pdfLink: '1AgPHevN5dko0-vr1ttGWQdv86tSNpNYP' },
  { name: 'Embossed Veneer',image:require("../assets/images/catalogues/Veneers/VP_Embossed Veneer.png"),  pdfLink: '1cnuF7gU57CZO5sPfhANjSOZ0JuZsKDVP' },

  
];

const laminates = [
  { name: 'Greenlam Laminates',image:require("../assets/images/catalogues/Laminates/Greenlam Laminates-Vikas Plywood.png"),  pdfLink: '1P7crjAaxEmvzD-7x60Pp6DZopZa7w_7V' },
  { name: 'Merino Laminates',image:require("../assets/images/catalogues/Laminates/Merino Lam_Vikas Plywood.png"),  pdfLink: '1pmXxsN54ru-dImGqnAkRtg1n8JXSruIm' },
  { name: 'Royale Touche',image:require("../assets/images/catalogues/Laminates/Royale touche_Vikas Plywood.png"),  pdfLink: '19KgbtOu9aKb_G35dMsUftgVn3HkocLhY' },
  { name: 'Century Laminates',image:require("../assets/images/catalogues/Laminates/Century Lam_Vikas Plywood.png"),  pdfLink: '1pkRRLe4lWmp8q_7_mmyEF1vQ2o-YW8xL' },
];

const woodenFlooring = [
  { name: 'Mikasa',image:require("../assets/images/catalogues/Flooring/Mikasa engineered flooring_Vikas Plywood.png"),  pdfLink: '11aql0LhSt8-hOcd2ClNs2WiQHw0QVN8E' },
  { name: 'Xylos',image:require("../assets/images/catalogues/Flooring/Xylos wooden flooring.png"),  pdfLink: '17x8ScJ25Dm3V29Z2PgJkzNVlkruAVEqM' },
  { name: 'Kronotex',image:require("../assets/images/catalogues/Flooring/Kronotex laminate flooring_Vikas Plywood.png"),  pdfLink: '1OLcSVV2qGOpVTaTugC6ropuQWu2XJOkD' },
  { name: 'VIN Flooring',image:require("../assets/images/catalogues/VIN/VP website thumbnails-18.png"),  pdfLink: '19ggnQ1-efc1VN-9Zpy7imYTExvMA4mDm' },

];

const vin = [
  { name: 'VIN Natural Veneers',image:require("../assets/images/catalogues/VIN/VP website thumbnails-16.png"),  pdfLink: '1pqXjBstIoxPv1UFMxaDy63HShJWBn40R' },
  { name: 'VIN Flooring',image:require("../assets/images/catalogues/VIN/VP website thumbnails-18.png"),  pdfLink: '19ggnQ1-efc1VN-9Zpy7imYTExvMA4mDm' },
  { name: 'VIN Solid Wood Panels',image:require("../assets/images/catalogues/VIN/VP website thumbnails-19.png"),  pdfLink: '1hxrvRAU8yccwxdhlkw30XES6QZqWlRB3' },
  { name: 'VIN Pre-polished Veneers',image:require("../assets/images/catalogues/VIN/VP website thumbnails-17.png"),  pdfLink: '1AgPHevN5dko0-vr1ttGWQdv86tSNpNYP' },

];
const kitchen = [
  { name: 'Modular Interiors',image:require("../assets/images/catalogues/kitchen/VP website thumbnails_MF.png"),  pdfLink: '1z_w-AaHoKEpk6SH613i_dm6pIcuKXHIe' },
];
const wallPanels = [
  { name: 'AGT Wall Panels',image:require("../assets/images/catalogues/Wall panels/AGT wall panels_Vikas Plywood.png"),  pdfLink: '16SXd3GnZW0s74U05cUjgZXAFsr59BtDh' },
  { name: 'VIN Solid Wood Panels',image:require("../assets/images/catalogues/VIN/VP website thumbnails-19.png"),  pdfLink: '1hxrvRAU8yccwxdhlkw30XES6QZqWlRB3' },

];
export const Catalogues = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("ALL");

  // const openPopup = () => {
  //   setShowPopup(true);
  // };

  const closePopup = () => {
    setShowPopup(false);
  };
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  // const [isSticky, setIsSticky] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY + window.innerHeight;
  //     const documentHeight = document.documentElement.scrollHeight;
  //     const threshold = 0.835;

  //     setIsSticky(scrollPosition >= documentHeight * threshold);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  return (
    <>
       <Helmet>
        <title>Catalogues - Vikas Plywood - One Stop Solution</title>
        <meta property="og:keywords" content=""/>
        <meta name="keywords" content=""/>
        <meta name="description" content="Download product catalogues to explore our range and designs, empowering informed choices for your space. Download now.." />
        <meta property="og:url" content="https://www.vikasplywood.com" />
        <meta name="og:image" content="https://www.vikasplywood.com/favicon.ico"/> 
      </Helmet>
      <Header logo="logomenu.png" color="black" />
      <div className="mx-[2vw]  overflow-hidden mb-[50px]">
        <div>
          <div className="flex lg:flex-row  flex-col justify-between">
            <h1 className="flex mx-[2vw] max-md:mb-3 mt-[100px] lg:mt-[9vw] justify-start items-start [font-family:'PP_Hatton-medium',Helvetica] text-[6vw] lg:text-[3.2vw] lg:leading-[99%] font-medium  text-[#000] relative text-left z-[25] ">
            Catalogues
            </h1>
            <h1 className="flex max-md:flex-wrap lg:text-[20px] text-[2.8vw] max-md:justify-center font-['Poppins'] md:items-end gap-3">
              <button className={selectedCategory === 'ALL' ? 'bg-[#6d744c] transition-all duration-100 text-white px-[1vw]' : ''} onClick={() => handleCategoryClick('ALL')}>ALL</button>
              <button className={selectedCategory === 'VIN' ? 'bg-[#6d744c] transition-all duration-100 text-white px-[1vw]' : ''} onClick={() => handleCategoryClick('VIN')}>VIN</button>
              <button className={selectedCategory === 'Veneer' ? 'bg-[#6d744c] transition-all duration-100 text-white px-[1vw]' : ''} onClick={() => handleCategoryClick('Veneer')}>Veneer</button>
              <button className={selectedCategory === 'Wall Panels' ? 'bg-[#6d744c] transition-all duration-100 text-white px-[1vw]' : ''} onClick={() => handleCategoryClick('Wall Panels')}>Wall Panels</button>
              <button className={selectedCategory === 'Modular Interiors' ? 'bg-[#6d744c] transition-all duration-100 text-white px-[1vw]' : ''} onClick={() => handleCategoryClick('Modular Interiors')}>Modular Interiors</button>
              <button className={selectedCategory === 'Wooden Flooring' ? 'bg-[#6d744c] transition-all duration-100 text-white px-[1vw]' : ''} onClick={() => handleCategoryClick('Wooden Flooring')}>Wooden Flooring</button>
              <button className={selectedCategory === 'Laminates' ? 'bg-[#6d744c] transition-all duration-100 text-white px-[1vw]' : ''} onClick={() => handleCategoryClick('Laminates')}>Laminates</button>
            </h1>

          </div>
          <div className="bg-black my-3 h-[2px]" />
        </div>
        
        {(selectedCategory === 'ALL' || selectedCategory === 'VIN') && (
          <>
            <h1  style={{ position: 'sticky', top: '6vw' }}  className="mx-[1vw] sticky top-2   [font-family:'PP_Hatton-medium',Helvetica] md:text-[2vw] max-md:my-2 text-[6vw] flex justify-center text- lg:justify-start">VIN</h1>
            <ProductList products={vin} />
          </>
        )}
       
        {(selectedCategory === 'ALL' || selectedCategory === 'Veneer') && (
          <>
            <h1  style={{ position: 'sticky', top: '6vw' }}  className="mx-[1vw] sticky top-2   [font-family:'PP_Hatton-medium',Helvetica] md:text-[2vw] max-md:my-2 text-[6vw] flex justify-center text- lg:justify-start">Veneer</h1>
            <ProductList products={veneers} />
          </>
        )}
        
        {(selectedCategory === 'ALL' || selectedCategory === 'Wall Panels') && (
          <>
            <h1 className="mx-[1vw] [font-family:'PP_Hatton-medium',Helvetica] md:text-[2vw] max-md:my-2 text-[6vw] flex justify-center text- lg:justify-start">Wall Panels</h1>
            <ProductList products={wallPanels} />
          </>
        )}
        {(selectedCategory === 'ALL' || selectedCategory === 'Modular Interiors') && (
          <>
            <h1  style={{ position: 'sticky', top: '6vw' }}  className="mx-[1vw] sticky top-2   [font-family:'PP_Hatton-medium',Helvetica] md:text-[2vw] max-md:my-2 text-[6vw] flex justify-center text- lg:justify-start">Modular Interiors</h1>
            <ProductList products={kitchen} />
          </>
        )}
        {(selectedCategory === 'ALL' || selectedCategory === 'Wooden Flooring') && (
          <>
            <h1 className="mx-[1vw] [font-family:'PP_Hatton-medium',Helvetica] md:text-[2vw] max-md:my-2 text-[6vw] flex justify-center text- lg:justify-start">Wooden Flooring</h1>
            <ProductList products={woodenFlooring} />
          </>
        )}
          {(selectedCategory === 'ALL' || selectedCategory === 'Laminates') && (
            <>
              <h1 className="mx-[1vw] [font-family:'PP_Hatton-medium',Helvetica] md:text-[2vw] max-md:my-2 text-[6vw] flex justify-center text- lg:justify-start">Laminates</h1>
              <ProductList products={laminates} />
            </>
          )}

      </div>
      <Footer />
      {showPopup && <FormPopup onClose={closePopup} />}
    </>
  );
};

export default Catalogues;
