import React, { useRef, useState } from 'react';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const Gallery = ({ images , style }) => {
    const galleryRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);

    const scrollRight = () => {
        const scrollAmount = 400;
        const scrollBehavior = 'smooth';

        galleryRef.current.scrollBy({
            left: scrollAmount,
            behavior: scrollBehavior
        });
        setShowLeftArrow(true);
    };

    const scrollLeft = () => {
        const scrollAmount = -400; // Adjust scroll amount as needed
        const scrollBehavior = 'smooth'; // Smooth scrolling behavior

        galleryRef.current.scrollBy({
            left: scrollAmount,
            behavior: scrollBehavior
        });
    };

    const handleScroll = () => {
        const scrollLeft = galleryRef.current.scrollLeft;
        setShowLeftArrow(scrollLeft > 0);
    };

    return (
        <>
            <div>
                <h1 className={`[font-family:'PP_Hatton-medium',Helvetica] ${style}  font-medium mt-[3vw] text-[6vw] md:text-[4vh] text-black`}>
                    Inspiration Gallery
                </h1>
            </div>
            <div className={`relative max-md:hidden ${style} left-[5vh] top-[4vh]`}>
                <button className='absolute  w-[10vw]  h-[70vh] flex justify-center items-center right-0 mr-[2vw]  text-white' onClick={scrollRight}>
                    <img className='h-[70vh] absolute z-0  ' src={require("../../assets/images/shadow.png")} alt="" />
                    <FaArrowRight className='z-10 absolute' />
                </button>
                {showLeftArrow && (
                    <button className='absolute h-[70vh] flex justify-center items-center  left-0 ml-[2vw] text-white' onClick={scrollLeft}>
                        {/* <img className='h-[70vh] absolute z-0 ' src={require("../../assets/images/shadow.png")} alt="" /> */}
                        <FaArrowLeft />
                    </button>
                )}
            </div>
            <div className='gallery-container' ref={galleryRef} onScroll={handleScroll}>
                {images.map((image, index) => (
                    <img
                        key={index}
                        className={`m-[2vw] ml-0 w-[70vw] h-[300px] mr-[4vw] max-md:object-cover md:w-[50vw] md:h-[70vh]`}
                        src={image.src}
                        alt={image.alt}
                        loading='lazy'
                    />))}
            </div>
        </>
    );
};

export default Gallery;
