import React, { useState } from 'react';

const Enquiry = ({ image }) => {
  const [selectedOption, setSelectedOption] = useState("6mm");

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleEnquiry = () => {
    const productName = "GreenPly";
    const phoneNumber = "+917093573311";
    const productNameBold = `*${productName}*`; 
    const selectedOptionBold = `*${selectedOption} size*`; 
    const message = `I am interested in\nname: ${productNameBold} \nsize: ${selectedOptionBold}.\nPlease provide more information.`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, '_blank');
  };
  
  

  return (
    <div className='m-[2vh] border-[1px] border-solid border-black'>
      <div className='p-[1vw] flex lg:flex-row flex-col border-s border-black'>
        <div>
          {image==="Mikasa MR+" ?(
          <img className='lg:w-[30vw] w-[400px] mx-auto h-[300px] md:h-[50vh]' src={require(`../../assets/images/products/plywood/Pictures/Mikasa MR.jpg`)} alt="" />
          ):(
            <img className='lg:w-[30vw] w-[400px] mx-auto h-[300px] md:h-[50vh]' src={require(`../../assets/images/products/plywood/Pictures/${image}.jpg`)} alt="" />
          )}
          
        </div>
        <div className='flex px-[1vw] flex-col justify-between'>
        <p className="[font-family:'PP_Hatton-medium',Helvetica] lg:mb-[1vw] text-[7vw] max-lg:my-2 md:text-[4.5vh]">{image}</p>

          <h1>
           <p className='md:text-[1.2vw] max-md:my-1'> <span className="font-bold md:text-[1.3vw] ">Standard size</span> : 8x4 sqft.</p>
          </h1>
          <div>
          <h1 className="font-bold md:text-[1.3vw] max-md:my-1">Thickness:</h1> 
          <p className='flex md:text-[1.2vw] max-md:gap-[2vw]   font-normal max-md:mb-2 md:my-[1vw]'>
       
          <button
            className={` px-[0.2vw] py-[0.2vw] mx-[0.2vw] ${selectedOption === "6mm" ? "border-[1.5px] border-black" : ""}`}
            onClick={() => handleOptionSelect("6mm")}
          >
              6mm
          </button>
          <button
            className={`px-[0.2vw] py-[0.2vw] mx-[0.2vw] ${selectedOption === "8mm" ? "border-[1.5px] border-black" : ""}`}
            onClick={() => handleOptionSelect("8mm")}
          >
              8mm
          </button>
          <button
            className={` px-[0.2vw] py-[0.2vw] mx-[0.2vw] ${selectedOption === "12mm" ? "border-[1.5px] border-black" : ""}`}
            onClick={() => handleOptionSelect("12mm")}
          >
              12mm
          </button>
          <button
            className={` px-[0.2vw] py-[0.2vw] mx-[0.2vw] ${selectedOption === "16mm"? "border-[1.5px] border-black" : ""}`}
            onClick={() => handleOptionSelect("16mm")}
          >
              16mm
          </button>
          <button
            className={` px-[0.2vw] py-[0.2vw] mx-[0.2vw] ${selectedOption === "18mm" ? "border-[1.5px] border-black" : ""}`}
            onClick={() => handleOptionSelect("18mm")}
          >
              18mm
          </button>
        </p>
        </div>
          <button className='bg-[#6d744c] font-serif p-3 w-full text-white' onClick={handleEnquiry}>
            Enquire now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
