import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { staggerContainer, fadeIn } from "../../utils/motion";
import VideoLoader from "./VideoLoader";
import { useInView } from "react-intersection-observer";
import { Helmet } from 'react-helmet';

const texts = [
  {
    heading: "1975",
    text: `Established in 1975, Niranjan Lal Agarwal planted our first humble storefront in the heart of Hyderabad's historic Patthargatti market. Inspired by the bustling energy of the city and driven by a sharp business sense, Niranjan Lal embarked on a journey to fill a gap in the market; bringing fine plywood to discerning customers. <br /><br /> Named after his son Vikas, born the same year, our company was founded in the spirit of family, and a commitment to Vikas (sanskrit for ''progress'').`,
  },
  {
    heading: "2010",
    text: ` As Hyderabad's skyline transformed, so did Vikas Plywood's approach to business.
      <br />  <br />  Embracing the city's burgeoning demand for innovative design, we embarked on a global exploration. Led by Vikas Goel's futuristic vision and Niranjan Lal's traditional wisdom, we ventured into uncharted territories: importing exclusive wooden products to redefine Hyderabad's design narrative.
     <br />  <br />  What began as a leap of faith soon flourished into a curated collection; from Germany to Turkey, and Taiwan to Australia, the world became our oyster, thus elevating architectural possibilities and inspiring creativity across the city.`,
  },
  {
    heading: "2024",
    text: ` As we enter our 50th year in the industry, our commitment to growth remains unchanged.
      <br />  <br /> Infused with the essence of our heritage and the vision of our third generation, we established an exemplary showroom in the heart of Hyderabad. A retail space where the finest woodcraft harmonizes with contemporary design, reflecting our dedication to redefine industry standards.
      <br /> <br />  Guided by family values, we believe in nurturing relationships over everything else. This is what sets us a class apart in our industry. Join us as we celebrate 50 years of excellence and continue to grow together.`,
  },
];

const videos = [
  { src: require("../../assets/images/about/aboutt1.mp4"), alt: "Old bazaars of Hyderabad displaying the streets of Charmina" },
  { src: require("../../assets/images/about/aboutt2.mp4"), alt: "Different wooden textures from across the world at Vikas Plywood" },
  { src: require("../../assets/images/about/aboutt3.mp4"), alt: "Showroom experience at Vikas Plywood" },
];

const PictureAndTextComponent = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);
  const lastWheelEventTime = useRef(0);
  const wheelEventThreshold = 1500;
  const { inView, ref } = useInView({ threshold: 0.99, triggerOnce:true});

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const handleWheel = (e) => {
    const currentTime = Date.now();
    const timeSinceLastWheelEvent = currentTime - lastWheelEventTime.current;
    if (timeSinceLastWheelEvent >= wheelEventThreshold) {
      if (e.deltaY > 0) {
        if (currentTextIndex < texts.length - 1) {
          const nextTextIndex = currentTextIndex + 1;
          const nextVideoIndex = (currentVideoIndex + 1) % videos.length;

          setCurrentTextIndex(nextTextIndex);
          setCurrentVideoIndex(nextVideoIndex);
          setIsVideoLoaded(false);

          if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.load();
            videoRef.current.play().catch((error) => {
              console.error("Error playing video:", error);
            });
          }
        }
      } else {
        if (currentTextIndex > 0) {
          const nextTextIndex = currentTextIndex - 1;
          const nextVideoIndex =
            (currentVideoIndex - 1 + videos.length) % videos.length;

          setCurrentTextIndex(nextTextIndex);
          setCurrentVideoIndex(nextVideoIndex);
          setIsVideoLoaded(false);

          if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.load();
            videoRef.current.play().catch((error) => {
              console.error("Error playing video:", error);
            });
          }
        }
      }

      lastWheelEventTime.current = currentTime;
    }
  };

  useEffect(() => {
    if (inView) {
      window.addEventListener("wheel", handleWheel, { passive: false });
      setIsMuted(false);
    } else {
      setIsMuted(true);
    }

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [inView, handleWheel]);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (inView) {
        setIsMuted(false);
      }
    };

    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
    };
  }, [inView]);

  let lineWidth, dotPosition, timelineRotation;
  switch (currentTextIndex) {
    case 0:
      lineWidth = "50%";
      dotPosition = "50%";
      timelineRotation = "rotate-180";
      break;
    case 1:
      lineWidth = "100%";
      dotPosition = "50%";
      timelineRotation = "rotate-180";
      break;
    case 2:
      lineWidth = "50%";
      dotPosition = "50%";
      timelineRotation = "rotate-0";
      break;
    default:
      lineWidth = "100%";
      dotPosition = "100%";
      timelineRotation = "";
  }

  return (
    <div ref={ref} className={`z-10 bg-[#F7F3EA] overflow-hidden`}>
      <Helmet>
        <title>About - Vikas Plywood - One Stop Solution</title>
        <meta name="description" content="Family-owned, guided by vision & values of respect and familial ethics. We believe in growing together. Learn more." />
        <meta property="og:url" content="https://www.vikasplywood.com/about " />
        <meta property="og:keywords" content="Vikas plywood, Family business in Hyderabad, Let's grow together, Goel family"/>
        <meta name="keywords" content="Vikas plywood, Family business in Hyderabad, Let's grow together, Goel family"/>
        <meta name="og:keywords" content="Vikas plywood, Family business in Hyderabad, Let's grow together, Goel family"/>
        <meta property="og:type" content="website" />
      </Helmet>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.01 }}
        className="grid grid-cols-2 relative"
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={`text-${currentTextIndex}`}
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "-100%" }}
            transition={{ duration: 0.7 }}
            className="md:h-screen max-md:my-5 max-md:mx-[5vw] md:w-screen flex flex-col md:items-start justify-center"
          >
            <h className="relative w-[65vw] flex max-lg:font-normal md:justify-start md:items-center lg:text-[1.7vw] text-[22px] font-thin leading-6 lg:leading-[2.4vw] text-[#000] z-[4]">
              <p className="mx-[4vw] [font-family:'PP_Hatton-medium',Helvetica] lg:text-[3vw] text-[25px] font-medium lg:leading-[2.4vw] text-[#000] z-[4]">
                {texts[currentTextIndex].heading}
                <p
                  dangerouslySetInnerHTML={{ __html: texts[currentTextIndex].text }}
                  className="mt-[1vw] font-thin font-['Poppins'] lg:leading-[2vw] lg:text-[3vh]"
                />
              </p>
              <div className={`timeline ${timelineRotation}`}>
                <motion.div
                  className="line"
                  transition={{ duration: 1 }}
                  animate={{ width: lineWidth }}
                />
                <motion.div
                  className="dot"
                  transition={{ duration: 0.7 }}
                  animate={{ left: dotPosition }}
                />
              </div>
            </h>
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            className="bg-[#000000f8] md:absolute right-0 w-screen md:w-[35vw] md:h-screen h-[70vh]"
            variants={fadeIn('up', 'tween', 0.2, 0.7)}
          >
            {!isVideoLoaded && <VideoLoader />}
            <motion.div
              key={`text-${currentTextIndex}`}
              initial={{ opacity: 0.5, y: "50%" }}
              animate={{ opacity: 1, y: 0.5 }}
              transition={{ duration: 0.7 }}
              className="w-full h-full object-cover"
            >
              <video
                className="w-full h-full object-cover"
                autoPlay
                loop
                muted={isMuted}
                onCanPlayThrough={handleVideoLoad}
                ref={videoRef}
                aria-label={videos[currentVideoIndex].alt}
              >
                <source src={videos[currentVideoIndex].src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default PictureAndTextComponent;