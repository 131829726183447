import React from 'react'
import Header from '../components/Navbar/Navbar'
import ProductsMain from '../components/products/ProductsMain'
import { Helmet } from 'react-helmet';

const Products = () => {

  return (
    <div className=' overflow-hidden'>
       <Helmet>
        <title>Products - Vikas Plywood - One Stop Solution</title>
        <meta name="description" content="Top-quality materials from worldwide: plywood, wall panels, veneers, laminates, wooden flooring, modular kitchens and wardrobes. Enquire now." />
        <meta property="og:url" content="https://www.vikasplywood.com" />
        <meta property="og:keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak " />
        <meta name="keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak " />
      </Helmet>
          <Header logo="logo.png" color="white" />
          <ProductsMain/>
    </div>
  )
}

export default Products