import React from 'react';

export default function OurTeam() {
  const imageUrls = [
    require("../../assets/images/about/Employee/Copy of _DSC4050-Enhanced-NR-2.jpg"),
    require("../../assets/images/about/Employee/Copy of _DSC4124-Enhanced-NR.jpg"),
    require("../../assets/images/about/Employee/Copy of _DSC4070-Enhanced-NR.jpg"),
    require("../../assets/images/about/Employee/Copy of _DSC4163-2.jpg"),
    require("../../assets/images/about/Employee/_DSC4097.jpg"),
    require("../../assets/images/about/Employee/_DSC4057.jpg"),
    require("../../assets/images/about/Employee/_DSC4077.jpg"),
    require("../../assets/images/about/Employee/_DSC4102.jpg"),
    require("../../assets/images/about/Employee/_DSC4094.jpg"),
    require("../../assets/images/about/Employee/_DSC4086.jpg"),
    require("../../assets/images/about/Employee/_DSC4080.jpg"),
    require("../../assets/images/about/Employee/_DSC4083.jpg"),
    require("../../assets/images/about/Employee/_DSC4089.jpg"),
    // require("../../assets/images/about/Employee/2.png"),
    // require("../../assets/images/about/Employee/2.png"),
  ];

  const names = [
    'Vikas Goel',
    'Ruchika Goel',
    'Ishaan Goel',
    'Nishika Goel',
    'Deepak Sain',
    "Rocky",
    "Kranthi Kumar",
    "P.Sai Kumar",
    "Rajesh Ammireddi",
    "N. Sumeet",
    "Rishitha Mysa",
    "S. Venkatesh",
    "Prabhu Raj",
    // "Palle Ritesh",
    // "Jay Tiwari"
  ];
  const roles = [
    'Partner & CEO',
    'Partner & CEO',
    'Chief Financial Officer',
    'Chief Marketing Officer',
    "Store Manager",
    "General Manager",
    "Senior Sales Manager",
    "Sales Executive",
    "Accounts Manager",
    "Junior Accountant",
    "Executive Admin",
    "Site Supervisor",
    "Collections Manager",
    "Ware Housing Manager",
    // "Assistant Ware House Manager",
    // "Ware Housing Manager"
  ];



  return (
    < div className='bg-[#F7F3EA] '>
      <h className="flex mx-auto text-center [font-family:'PP_Hatton-medium',Helvetica]  items-center  justify-center md:text-[3vw] text-[25px] md:my-[2vw] font-thin text-[#000]   z-[4]">
        Our Team
      </h>
      <div className=' mx-auto bg-[#f7f3ea] m-4 p-6 grid sm:grid-cols-3 grid-cols-2  md:grid-cols-4  lg:grid-cols-5'>
        {[...Array(13)].map((_, index) => (
          <div key={index} className='relative m-[1vw] '>
            <div className='bg-gray-300  '>
              <img src={imageUrls[index % imageUrls.length]} alt={names[index % names.length]} className='w-full object-fit h-[220px] md:h-[45vh]' />
            </div>
            <div className='relative flex-col flex  bottom-0 left-0 right-0  bg-opacity-75 text-white text-start p-2'>
              <h className="text-sm font-light font-['Poppins'] text-black">{names[index % names.length]}</h>
              <h className='text-xs font-light text-black'>{roles[index % roles.length]}</h>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
}
