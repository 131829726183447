import React, { useState, useEffect } from 'react';
import Header from "../components/Navbar/Navbar";
import { Footer } from "../components/Footer";
import GetDirection from "../components/Contact/GetDirection";
import { InstaComponent } from "../components/Contact/InstaComponent";
import axios from 'axios';
import { UArrowUpRight } from '../icons/UArrowUpRight/UArrowUpRight';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const [instagramPosts, setInstagramPosts] = useState([]);
  useEffect(() => {
    const fetchInstagramPosts = async () => {
      try {
        const isMobile = window.innerWidth <= 768; 
        const limit = isMobile ? 8 : 9;
        
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink&access_token=IGQWRNemFGRlcwTC1DYjNGc3BBZAVhpVDFGQzNQaEVUQVhEVi02d0wyZAzBtcXk4SENfXzZAFV0JtUUhUWGw2YUZAybUJpNjVISGowZAmk1TEQtbzdsZAk5jYkxEUkFoYzI4NHRIMmJkVHlkRjVHM0szbzVhRnZAXRjd5ZAmMZD&limit=${limit}`
        );
        setInstagramPosts(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
      }
    };

    fetchInstagramPosts();
  }, []);

  const handleInstagramClick = (permalink) => {
    window.open(permalink, '_blank');
  };
  return (
    <>
       <Helmet>
        <title>Contact Us - Vikas Plywood - One Stop Solution</title>
        <meta name="description" content="Hey! At Vikas Plywood we are passionate about helping you create a space that's uniquely yours. With our top-quality products and expert guidance, we're here to bring your vision to life. Contact us today and let’s grow together!
" />
        <meta property="og:url" content="https://www.vikasplywood.com" />
        <meta property="og:keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak " />
        <meta name="keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak " />
      </Helmet>
      <Header logo="logomenu.png" color="black" />
      <div>
        <div className="overflow-hidden relative ">
          <img
            className="absolute  max-md:hidden right-10 h-[100vh] z-10  "
            alt="Mask group"
            src={require('../assets/images/contact/gradient.png')}
          />
          <div className="absolute top-2/4 z-[1] right-[-30%]">
            <div className="grid gap-2  -rotate-90 relative top-20    font-sans grid-cols-3 underline uppercase md:hidden text-[4vw] mt-[1vw]   ">
              <a href="https://www.facebook.com/vikasplywoods" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
              <a href="https://www.instagram.com/vikasplywood" target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
              <a href="https://in.linkedin.com/company/vikas-plywood-and-veneers" target="_blank" rel="noopener noreferrer">
                Linkedin
              </a>
            </div>
          </div>

          <div className="bg-[#f7f3ea] flex flex-row  w-full">
            <div className="bg-second flex flex-col w-screen overflow-hidden relative">
              <div className="relative max-md:hidden top-[-5vw] right-[2vw]">
                <img className="absolute  object-fill   w-[75vw]   " src={require("../assets/images/contact/contact.png")} alt="" />
              </div>
              <div className=" h-screen mx-[3vw] flex flex-col justify-between md:justify-end text-[20px] lg:text-[2vw]">
                <div className="md:max-w-[40vw] max-md:mt-28  font-thin lg:text-[1.5vw] max-lg:text-[25px] max-md:text-[20px] [font-family:'Poppins']">
                  Hey! At Vikas Plywood we are passionate about helping you create a space that's uniquely yours. With our top-quality products and expert guidance, we're here to bring your vision to life. Contact us today and let’s grow together!
                </div>
                <div className=" md:hidden max-md:mt-10  justify-between  flex max-w-[40%] md:max-w-[40%] items-center gap-[0.1vw] p-[0.5vw] border border-solid border-[color:var(--third)]">
                  <div className="relative   [font-family:'PP_Hatton-medium',Helvetica] lg:text-[2vw] whitespace-nowrap text-[#6D744C] text-[6vw]  ">
                  <Link target='_blank' className='ml-2' to={"https://wa.me/+917093573311"}>
                    Let’s Talk
                    </Link>
                  </div>
                  <UArrowUpRight className="!relative  md:!w-[3vw] md:!h-[4vh]" />
                </div>
                <div className="relative  w-screen md:hidden ">
                  <img className=" w-full max-sm:h-full max-md:h-[500px]  brightness-200" src={require("../assets/images/contact/contactphone.png")} alt="" />
                </div>

                <div className="md:relative bottom-0 max-lg:text-[5vw] absolute max-md:text-center  mb-8 md:mb-[0.5vw]">
                  <h1 className="[font-family:'PP_Hatton-medium',Helvetica]  lg:text-[2vw] my-[1vw]">Contact Information</h1>
                  <Link target='_blank' to={"https://wa.me/+917093573311"} className="[font-family:'Poppins']  font-thin"> +91 7093573311</Link>
                  <h2 className="[font-family:'Poppins'] font-thin">marketing@vikasplywood.com</h2>
                </div>
                <div className=" mb-[2vw] max-md:hidden   justify-center items-center   flex max-w-[20%] gap-[0.1vw] p-[0.5vw] border border-solid border-[color:var(--third)]">
                  <div className="relative flex  justify-center items-center [font-family:'PP_Hatton-medium',Helvetica] max-lg:text-[23px] max-md:text-[20px] lg:text-[2vw] whitespace-nowrap text-[#6D744C]   ">
                  <Link target='_blank' to={"https://wa.me/+917093573311"}>
                    Let’s Talk
                    </Link>
                  </div>
                  <UArrowUpRight className="!relative !w-[3vw] !h-[4vh]" />
                </div>
              </div>
              <div>
              </div>

            </div>
            <div className="flex bottom-[2vw] max-md:hidden z-10 right-[2vw] gap-[2vw] absolute  flex-col justify-center items-center text-[25px]  ">
              <a href="https://www.instagram.com/vikasplywood" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram fa-2x"></i>
              </a>
              <a href="https://www.facebook.com/vikasplywoods" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook fa-2x"></i>
              </a>
              <a href="https://in.linkedin.com/company/vikas-plywood-and-veneers" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin fa-2x"></i>
              </a>
            </div>
          </div>
        </div>
        <div>
          <GetDirection />
        </div>
        <div className="mx-[2vw] mt-[5vw] ">
          <div className=" text-black md:leading-[2.6vw] mb-[2vw] [font-family:'PP_Hatton-medium',Helvetica] text-[5vw] md:text-[3vw] font-medium">
            <h1> Join Our Instagram Community</h1>
            <h1 className="font-['Poppins'] md:text-[2vw]"><Link target='_blank' to={"https://www.instagram.com/vikasplywood/"}>@vikasplywood</Link></h1>
          </div>
          <div className="grid gap-[1vw] grid-cols-2 md:grid-cols-3">
            {instagramPosts.map((post) => (
              <InstaComponent
                key={post.id}
                media={{
                  media_type: post.media_type,
                  media_url: post.media_url,
                  permalink: post.permalink,
                }}
                handleClick={() => handleInstagramClick(post.permalink)}
              />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>

  )
}

export default ContactUs
