import React from 'react';
import { CgSpinner } from "react-icons/cg";

const VideoLoader = () => {
  return (
    <div className="flex items-center  pointer-events-none justify-center h-full">
      <div className="w-16 h-16">
      <CgSpinner size={50} className="mt-1 text-white animate-spin" />
        </div>
    </div>
  );
};

export default VideoLoader;
