import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../Navbar/Navbar";
import { useParams, Link } from "react-router-dom";
import { Footer } from "../Footer";
import Gallery from "../products/Gallery";


export default function ImageComponent({}) {
  const { image, variety, category } = useParams();
  const decodedVariety = variety
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  const [zoomIn, setZoomIn] = useState(false);
  useEffect(() => {
    setZoomIn(true);
  }, []);
  useEffect(() => {
    const handleRouteChange = () => {
      setZoomIn(false);
    };

    const unlisten = () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
    window.addEventListener("popstate", handleRouteChange);

    return unlisten;
  }, []);

  const descriptionMap = {
    myscape_villas: `<b>Villa 63 — Rooted in tradition, embraced by nature.</b><br /><br/> This home designed by <a target= "_blank" href="https://www.instagram.com/aamir_hameeda/">@aamir_hameeda </a> blurs the boundaries between the indoors and the outdoors encompassing a true resort living.<br /> With tons of natural light, greenery, and a subtle Indian charm, this home creates an intimate connection with its inhabitants. `,
    shiv_house: `<b>Shiv House - Less is More.</b> <br /><br/> A minimal 5,000sqft. house designed by <a target= "_blank" href="https://www.instagram.com/aamir_hameeda/">@aamir_hameeda</a>, where he uses warm hues everywhere, blending the indoors with the outdoors, to create this sleek & tranquil home.`,
    "satwalekar’s_dental_clinic": `The Satwalekars come from a lineage of doctors dating back to the Nizam era. Their family-run hospital was the first private hospital in the Deccan region. The clinic had to move from the ancestral property in Old City to the more happening Jubilee Hills, all while preserving their rich family heritage. <br/><br/>
    The designer, Prianshi Sharma  (<a target= "_blank" href="https://www.instagram.com/designclusives/">@designclusives</a>), shares how she used a mix of pre-polished veneers and AGT wall panels to create subtle layers and textures in the space - “The entrance is a harmonious blend of neutral tones. The focal point of the entrance is a striking gold sign that exudes a sense of prestige, instantly capturing the attention of passersby. Fluted panels adorn the entrance, adding a touch of classical grandeur and creating a visually captivating facade".`,
    cara_cara: `<b>Cara Cara — The Shape of Water.</b> <br/><br/>
    A parametric ceiling design that celebrates the organic course of a water droplet. A bold imagination brought to life with the beautiful agility of Baltic Birch Ply.`,
    film_nagar_residence: `<b>Film Nagar — Such Great Heights .</b><br /><br/> A sprawling residence on the heights of Film Nagar where the lush natural greens and panoramic city views are complemented by our contemporary wooden ceilings. `,

  };
  const description = descriptionMap[variety] || "";

  const dataMap = {
    myscape_villas: {
      points: [
        <Link className="hover:underline" to="/products/WallPanels/agt_wall_panels/3771/369_german_oak">AGT 3771 - 369 German Oak</Link>,
        "Financial District, Hyderabad",
        "Residential",
        "Aamir Sharma, AANDH",
        "-",
      ],
    },
    shiv_house: {
      points: [  
        <span>
          <Link to="/products/Veneers/pre-polished_veneers" className="hover:underline">
            Textured Teak VIN Veneer
          </Link>
          <br />
          <Link to="/products/ExteriorMaterials/deck_flooring" className="hover:underline">
            IPE Deck Flooring
          </Link>
        </span>,
        "Financial District, Hyderabad",
        "Residential",
        "Aamir Sharma, AANDH",
        "Avinash Agarwal",
      ],
    },
    "satwalekar’s_dental_clinic": {
      points: [
        <span>

        <Link className="hover:underline" to="/products/WallPanels/agt_wall_panels/3783/728_soft_touch_stone_grey">AGT 3783 - 728 Soft Touch Stone Grey</Link> <br />
        <Link className="hover:underline" to="/products/Veneers/pre-polished_veneers">Pre-Polished VIN Veneer</Link>

        </span>,

        "Jubilee Hills, Hyderabad",
        "Commercial",
        "Prianshi Sharma, Designclusives",
        "-",
      ],
    },
    cara_cara: {
      points: [
        
        <Link className="hover:underline" to="/products/Plywood/birch_plywood">Birch Plywood</Link>,
        "Jubilee Hills, Hyderabad",
        "Commercial",
        "ARA Designs Naresh Jangid, CNC Designer",
        "-",
      ],
    },
    "film_nagar_residence": {
      points: [
        <span>
        <Link className="hover:underline" to="/products/WallPanels/agt_wall_panels/3771/369_german_oak">AGT 3771 German Oak</Link> <br /><p >AGT 114 Teak</p>
        </span>,

        "Film Nagar, Hyderabad",
        "Residential",
        "Aamir Sharma, AANDH",
        "-",
      ],
    },
  };
  

  const scape = [
    {src: require("../../assets/images/project/MyScape Villas/AGT 3771 - German Oak.jpg"), alt: "AGT 3771 German Oak wall profiles"},
    {src: require("../../assets/images/project/MyScape Villas/AGT Fluted Wall Panels.jpg"), alt: "AGT profiles installed in Myscape villas by Vikas Plywood"}
  ];
  
  
  const cara = [
    {src: require("../../assets/images/project/Cara Cara/Birch Plywood_Vikas Plywood.jpg"), alt: "Birch plywood carved in the shape of water"},
    {src: require("../../assets/images/project/Cara Cara/Cara Cara Hyderabad_Vikas Plywood.jpg"), alt: "Birch plywood by Vikas Plywood used on Cara Cara ceiling"}
  ];
  
  
  const clinic = [
    {src: require("../../assets/images/project/Satwalekar_s Clinic/KD Pre-Polished Veneer.jpg"), alt: "Pre-polished veneers by VIN"},
    {src: require("../../assets/images/project/Satwalekar_s Clinic/DSC09637 3.jpg"), alt: "Satwelkar’s Dental Clinic lobby"}
  ];
  
  
  const shiv = [
    {src: require("../../assets/images/project/shiv_house1.jpg"), alt: "Wide angle shot of the living room having VIN veneers"},
    {src: require("../../assets/images/project/SHIV House/Natural Veneer.jpg"), alt: "Textured Teak Veneer"},
    {src: require("../../assets/images/project/SHIV House/IPE Deck.jpg"), alt: "IPE Deck flooring done in SHIV house"},
    {src: require("../../assets/images/project/SHIV House/Pre-polished Veneer.jpg"), alt: "Pre-polished veneers by VIN"}
  ];
  
  const film = [
    {src: require("../../assets/images/project/Film Nagar Residence/_DSC9316.jpg"), alt: "AGT 114 Teak profiles installed on the ceiling"},
    {src: require("../../assets/images/project/Film Nagar Residence/_DSC9344.jpg"), alt: "AGT 114 Barcode series used in a residential project"},
    {src: require("../../assets/images/project/Film Nagar Residence/_DSC9354.jpg"), alt: "AGT 114 Teak in the balcony for semi-indoor use"},
    {src: require("../../assets/images/project/Film Nagar Residence/_DSC9358.jpg"), alt: "AGT 114 Teak wall panels"},
    {src: require("../../assets/images/project/Film Nagar Residence/_DSC9432-Edit.jpg"), alt: "AGT German oak shade applied on the ceiling"},
    {src: require("../../assets/images/project/Film Nagar Residence/_DSC9445.jpg"), alt: "AGT 3771 German oak"},
    {src: require("../../assets/images/project/Film Nagar Residence/_DSC9455.jpg"), alt: "AGT 114 Teak used in the balcony"},
    {src: require("../../assets/images/project/Film Nagar Residence/_DSC9477.jpg"), alt: "AGT 114 Teak used on the ceiling in the garage"}
  ];
  

  const { points, images } = dataMap[variety] || { points: [], images: [] };

  function getHeaderText(index) {
    switch (index) {
      case 0:
        return "Materials Used";
      case 1:
        return "Location";
      case 2:
        return "Project Type";
      case 3:
        return "Architect";
      case 4:
        return "Contractor";
      default:
        return "";
    }
  }

  return (
    <div>
      <motion.div className=" overflow-hidden">
        <div className={`z-[100] `}>
          <Header logo="logomenu.png" color="black" />
        </div>
        <div
          className={`new-component md:h-[80vh] h-[60vh] ${
            zoomIn ? "zoom-in" : "zoom-out"
          } relative`}
          style={{ position: "relative" }}
        >
          <h1 className="text-white whitespace-nowrap absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vw] md:text-[6vh]">
            {decodedVariety}
          </h1>
          <img
            className="overflow-hidden  object-cover md:h-[80vh] h-[60vh] w-screen"
            src={require(`../../assets/images/project/${variety}.jpg`)}
            alt={decodedVariety}
          />
          <div
            className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}
          >
            <Link to="/projects">
              <img
                src={require("../../assets/images/project/arrow.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="flex absolute text-white bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1">
            <Link to="/">Home</Link>/
            <p>Projects</p>/<p>{decodedVariety}</p>
          </div>
        </div>
        <div className="relative w-full pb-[5vh]  bg-[#F7F3EA] text-black ">
          <div className="mx-[3vw]">
            <div
              className="lg:max-w-[60%] border border-black md:text-[3vh] font-thin  font-['Poppins'] my-10"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className="md:w-[60vw] flex">
              <table className="w-full mb-[2vw]  border-collapse  md:text-[1.5vw]">
                <tbody>
                  {points.map((point, index) => (
                    <tr key={index} className=" ">
                      <td className=" border-b border-t border-solid border-black px-4 py-2">
                        <span className="font-Poppins ">{getHeaderText(index)}</span>
                      </td>
                      <td className="border-b border-t  border-solid border-black px-4 py-2">
                        {point}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
          </div>
        </div>
        <div className="ml-[5vw]">
            {variety==="myscape_villas" &&(
              <Gallery style="hidden"  images={scape} />
            )}
             {variety==="shiv_house" &&(
              <Gallery style="hidden" images={shiv} />

            )}
             {variety==="satwalekar’s_dental_clinic" &&(
              <Gallery style="hidden" images={clinic} />

            )}
             {variety==="cara_cara" &&(
              <Gallery style="hidden" images={cara} />

            )}
             {variety==="film_nagar_residence" &&(
              <Gallery style="hidden"   images={film} />
            )}
            </div>
      </motion.div>
      <Footer />
    </div>
  );
}
