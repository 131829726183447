import React from 'react'
import Header from '../components/Navbar/Navbar'
import ProjectHome from '../components/Project/ProjectHome'
import { Helmet } from 'react-helmet';

const Projects = () => {
  return (
    <div>
      <Helmet>
        <title>Projects - Vikas Plywood - One Stop Solution</title>
        <meta name="description" content="We are proud to have provided materials for top projects across various industries, including EA, Wells Fargo, Amazon India, Providence, Karachi bakery and more." />
        <meta property="og:url" content="https://www.vikasplywood.com" />
        <meta property="og:keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak " />
        <meta name="keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak, Projects by Vikas plywood, Best wooden-based supplier in town, AGT panels distributor, Top projects " />
      </Helmet>
      <Header logo="logomenu.png" color="black" />
      <ProjectHome />
    </div>
  )
}

export default Projects