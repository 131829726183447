import React from "react";
import Header from "../components/Navbar/Navbar";
import { Footer } from "../components/Footer";
import { Helmet } from 'react-helmet';

const Privacy = () => {
  return (
    <div>
         <Helmet>
        <title>Privacy - Vikas Plywood - One Stop Solution</title>
        <meta name="description" content=" This Privacy Policy
          outlines how we collect, use, disclose, and safeguard your personal
          information when you interact with us. By providing us with your
          information, you consent to the terms outlined in this policy." />
            <meta property="og:keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak " />
        <meta name="keywords" content="About, plywood, catalogue, designs, space, Vikas Plywood, vikas plywood, vikas plywood.com, vikasplywood.com, veneers, agt wall panels, AGT, Solid Wood Panels, Mikasa, Xylos, Kronotex, VIN Flooring, VIN Natural Veneers, Greenlam Laminates, Greenlam Decowood, Greenply Woodcrest, Hunsur Veneers, VIN Pre-polished Veneers, Greenlam Laminates, Merino Laminates, Royale Touche, Century Laminates, VIN Solid Wood Panels, AGT Wall Panels, Vikas Stories, Plywood, Adhesive, boutique, A Vikas Plywood Private Label, Boutique, Kitchens & Wardrobes, Kitchens, Wardrobes, Exterior Materials, Our Brand Partners, VIN Grip 153 Aerosol, 369 German Oak, 231 Plain White, 239 Light Walnut, 248 Teak, 251 Tobacco, 376 New Oak, 723 Soft Touch Black, 3011 London Blues, 251 Tobacco, 2050, Naturals Wood Panels, story, Smoked Alder, story, Walnut, Bleached Teak, Grey Limba, Inspiration Gallery, Deck Flooring, IPE Deck Flooring, WPC Deck Flooring, Exterior Cladding, WPC Cladding, VIN Teak " />
      </Helmet>
      <Header logo="logomenu.png" color="black" />
      <div>
        <h1 className="flex mb-[2vw]  justify-center max-lg:mt-[100px] mt-[10vw]  [font-family:'PP_Hatton-medium',Helvetica] md:text-[45px] font-medium md:leading-[56.7px] text-[#000] relative text-left overflow-hidden z-[25] ">
          Privacy Policy
        </h1>
      </div>
      <div className="mx-[4vw]  text-justify font-['Poppins'] font-thin flex flex-col gap-[2vw]">
        <p>
          Thank you for choosing Vikas Plywood. <br /> This Privacy Policy
          outlines how we collect, use, disclose, and safeguard your personal
          information when you interact with us. By providing us with your
          information, you consent to the terms outlined in this policy.
        </p>

        <p>
          <strong>Information We Collect:</strong>
          <br />
          <em>Personal Information:</em> We may collect personal information,
          including but not limited to your name, residential address, email ID,
          phone number, and payment information.
        </p>

        <p>
          <strong>How We Use Your Information:</strong>
          <br />
          We use the collected information for the following purposes:
          <ul>
            <li>
              To respond to your inquiries and provide information about our
              products and services.
            </li>
            <li>
              To send marketing communications, including updates, promotions,
              and newsletters, with your consent.
            </li>
            <li>To improve our products, services, and user experience.</li>
          </ul>
        </p>

        <p>
          <strong>Information Sharing:</strong>
          <br />
          We do not sell, trade, or otherwise transfer your personal information
          to external parties without your consent, except as required by law.
        </p>

        <p>
          <strong>Security:</strong>
          <br />
          We employ industry-standard security measures to protect your personal
          information from unauthorised access, disclosure, alteration, and
          destruction.
        </p>

        <p>
          <strong>Cookies:</strong>
          <br />
          We may use cookies and similar tracking technologies to track user
          interactions with our website. These technologies help us understand
          how users navigate our site, improve our website's functionality, and
          provide personalised experiences. Additionally, we may use Google Tags
          and Facebook Pixel to track user behaviour and interactions with our
          website for advertising and analytics purposes. These tools allow us
          to measure the effectiveness of our marketing campaigns, optimise our
          website's performance, and provide targeted advertisements to users
          who have visited our site. By using our website, you consent to the
          use of cookies and tracking technologies as described in this Privacy
          Policy. You can adjust your browser settings to disable cookies or opt
          out of personalised advertisements, but please note that this may
          affect your browsing experience.
        </p>

        <p>
          <strong>Your Choices:</strong>
          <br />
          You have the right to opt out of marketing communications at any time.
          To do so, please contact us at marketing@vikasplywood.com.
        </p>

        <p>
          <strong>Data Retention:</strong>
          <br />
          We retain your personal data for as long as necessary to fulfil the
          purposes outlined in this Privacy Policy, unless a longer retention
          period is required or permitted by law. After the applicable retention
          period has elapsed, your personal data will be anonymized or deleted.
        </p>

        <p>
          <strong>Updates to Privacy Policy:</strong>
          <br />
          We reserve the right to update this Privacy Policy periodically. The
          updated version will be posted on our website with the effective date.
        </p>

        <p>
          <strong>Contact Us:</strong>
          <br />
          If you have any questions or concerns regarding this Privacy Policy or
          your data, please contact us at marketing@vikasplywood.com. By
          providing your information, you acknowledge that you have read and
          understood this Privacy Policy.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
