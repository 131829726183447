import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../../Navbar/Navbar';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import PdfEnquiry from '../PdfEnquiry';
import { Footer } from '../../Footer';

export default function WoodenComponent() {
    const { image, variety } = useParams();
    let decodedVariety = variety.toLowerCase().replace(/_/g, ' ');
    decodedVariety = decodedVariety.replace(/(^|\s)\S/g, (match) => match.toUpperCase());
    const navigate = useNavigate();
    const location = useLocation();
    const { arrow } = location.state || {};
    console.log(arrow,"arrow")
    const [zoomIn, setZoomIn] = useState(false);
    useEffect(() => {
        setZoomIn(true);
    }, []);
    useEffect(() => {
        const handleRouteChange = () => {
            setZoomIn(false);
        };

        const unlisten = () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
        window.addEventListener('popstate', handleRouteChange);

        return unlisten;
    }, []);
    const descriptionMap = {
        engineered_wooden_flooring: `Experience the timeless beauty of real wood with our Engineered Wooden Flooring. Crafted for durability, it features layers of high-quality wood topped with an authentic hardwood veneer. <br/>  Enjoy the natural charm of wood with added stability and resistance to moisture and temperature changes. Explore top-notch brands such as Mikasa, Xylos, and Vin Flooring within our selection.`,
        "laminated_wooden_flooring": `Discover the perfect blend of affordability and style with Laminated Wooden Flooring. Emulating the look of real wood, it offers a cost-effective alternative without compromising aesthetics. <br/> Easy to install, low maintenance, and resistant to scratches and stains, it's an ideal choice for high-traffic areas.<br/> We proudly offer the Swiss brand Kronotex, known for its unique aesthetics and unparalleled quality. Kronotex laminate floors cater to diverse tastes, featuring a wide range of decors, from perfectly emulated wood grain to authentic stone structures. 
        `,
    };

    const description = descriptionMap[variety] || '';

    const pdfVarieties = {
        engineered_wooden_flooring: [
            { name: 'Mikasa', link: '11aql0LhSt8-hOcd2ClNs2WiQHw0QVN8E' },
            { name: 'Xylos', link: '17x8ScJ25Dm3V29Z2PgJkzNVlkruAVEqM' },
            { name: 'Vin Flooring', link: '19ggnQ1-efc1VN-9Zpy7imYTExvMA4mDm' },

        ],
        "laminated_wooden_flooring": [
            { name: 'Kronotex', link: '1OLcSVV2qGOpVTaTugC6ropuQWu2XJOkD' },
        ],
    };

    const selectedPdfs = pdfVarieties[variety] || [];
    return (
        <div className=' overflow-hidden'>
            <motion.div
            >
                <div className={`z-[100] `}>
                    <Header logo="logomenu.png" color="black" />
                </div>
                <div className={`new-component md:h-[80vh] h-[60vh] ${zoomIn ? 'zoom-in' : 'zoom-out'} relative`} style={{ position: 'relative' }}>
                    <h1 className="text-white whitespace-nowrap absolute   top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vw] md:text-[6vh]">
                        {decodedVariety}
                    </h1>
                    <img
                        className="overflow-hidden  object-cover md:h-[80vh] h-[60vh] w-screen"
                        src={require(`../../../assets/images/products/wooden_flooring/${variety}.jpg`)}
                        alt={decodedVariety}
                    />
                    <div className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}>
                        <Link onClick={() => navigate(-1)} to={`/products/WoodenFlooring`} >
                        <img className={arrow === 'white' ? 'invert' : 'invert-0'} src={require("../../../assets/images/project/arrow.png")} alt="" />
                        </Link>
                    </div>
                    <div className='flex absolute text-white bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1'>
                        <Link to="/">
                            Home
                        </Link>
                        /
                        <Link to="/products/WoodenFlooring">
                            Products
                        </Link>
                        /
                        <p >
                            WoodenFlooring
                        </p>
                        /
                        <p  >
                            {decodedVariety.split(' ')[0]}
                        </p>
                    </div>
                </div>
                <div className="relative w-full pb-[5vh]   bg-[#F7F3EA] text-black ">
                    <div className='mx-[3vw]' >
                        <div className="lg:max-w-[80%] border border-black md:text-[3vh] font-thin font-['Poppins'] my-10" dangerouslySetInnerHTML={{ __html: description }} />
                        <div className='bg-black h-[1px]' />
                        <div className='bg-black h-[1px]' />
                        <div className='grid border-2 border-red-500 md:grid-cols-2 grid-cols-1 '>
                            {selectedPdfs.map((pdf, index) => (
                                <PdfEnquiry key={index} variety={decodedVariety} category={"wooden_flooring"} name={pdf.name} link={pdf.link} />
                                ))}
                        </div>
                    </div>
                </div>
            </motion.div>
            <Footer />
        </div>
    );
}
